import { CalendarIcon, ClipboardCheckIcon, CubeIcon, QrcodeIcon } from '@heroicons/react/solid';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAxios } from '../../../helpers/AxiosContext';
import { LineBatch, LineRegular, LineSerial } from '../../components/ReceiptPopup';
import Loading from '../../general/Loading';
import { DetailsActions, DetailsBox, DetailsElement, DetailsList, DetailsRow, DetailsSubtitle, DetailsTitle } from '../../widgets/DetailComponents';
import { ActionButton } from '../../widgets/FancyButtons';
import { List, ListContainer, Row, RowElement, RowLine, RowTitle } from '../../widgets/ListComponents';
import { StatusTextBlack, StatusTextGray, StatusTextGreen, StatusTextOrange, StatusTextRed } from '../../widgets/StatusText';
import PopupWindow from '../../widgets/PopupWindow';

const SalesOrderDetails = () => {
  const { id } = useParams();
  const [isLoading, setLoading] = useState(true);
  const [salesOrder, setSalesOrder] = useState({});
  const [salesOrderLines, setSalesOrderLines] = useState([]);
  const axiosClient = useAxios();
  const [receiptData, setReceiptData] = useState([]);
  const [trackingNumber, setTrackingNumber] = useState('');
  const [openReceiptPopup, setOpenReceiptPopup] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    axiosClient.get(`api/salesorder/get_salesorder/${id}`)
      .then((response) => {
        console.log(response.data);
        setSalesOrder(response.data);
      })
      .catch((error) => {
        console.log(`Error ${error}`);
      }).finally(() => {
        setLoading(false);
      });
  }, [id]);

  const openCreateReceiptPopup = () => {
    const data = salesOrder.SalesOrderLines.map((x) => {
      x['value'] = x['Quantity'] - x['QuantityDelivered'];
      x['batchnumbers'] = [];
      x['serialnumbers'] = [];
      return x;
    });
    setReceiptData(data);
    setOpenReceiptPopup(true);
  };

  const submitReceipt = () => {
    axiosClient.post('api/salesorder/process_salesorder', { 'GoodsDeliveryLines': receiptData, 'SalesOrderID': id, 'TrackingNumber': trackingNumber })
      .then((response) => {
        console.log(response.data);
        navigate(`/goodsdelivery/details/${response.data}`);
      })
      .catch((error) => {
        setOpenReceiptPopup(true);
        console.log(`Error ${error}`);
        setLoading(false);
      });
  };

  if (isLoading) {
    return (
      <Loading></Loading>
    );
  }

  return (
    <div className="min-h-full">
      <PopupWindow setOpen={setOpenReceiptPopup} open={openReceiptPopup} submitForm={submitReceipt} title='Goederen ontangen'>
        <div className="mt-1">
          <input
            name="trackingnumber"
            type="text"
            value={trackingNumber}
            placeholder='Trackingnummer'
            onChange={(e) => setTrackingNumber(e.target.value)}
            className="
                appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md
                shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"/>
        </div>
        <br />
        {receiptData.map((line, index) => (
          <div
            key={line.ID}>
            <p className="flex items-center text-sm font-medium text-gray-700">
              {line.Item.Description}
            </p>
            <p className="flex items-center text-sm text-gray-500">
              Al ontvangen:
              <span className='font-medium text-gray-700'>
                &nbsp;{line.QuantityDelivered}/{line.Quantity} ({line.UnitDescription})
              </span>
            </p>
            {(line.Item.Barcode) ?
              <p className="flex items-center text-sm text-gray-500">
                <QrcodeIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />Barcode intern: &nbsp;
                <span className='font-medium text-gray-700'>
                  {line.Item.Barcode}
                </span>
              </p> :
              <p className="flex items-center text-sm text-gray-500">
                <QrcodeIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />Barcode intern bestaat niet
              </p>
            }
            {(line.Item.IsSerialItem) ?
              <LineSerial line={line} index={index} lines={receiptData} setLines={setReceiptData}></LineSerial> :
              (line.Item.IsBatchItem) ?
                <LineBatch line={line} index={index} lines={receiptData} setLines={setReceiptData}></LineBatch> :
                <LineRegular line={line} index={index} lines={receiptData} setLines={setReceiptData}></LineRegular>
            }
          </div>
        ))}
      </PopupWindow>
      <DetailsBox>
        <DetailsList>
          <DetailsSubtitle>Bestelnummer: {salesOrder.OrderNumber}</DetailsSubtitle>
          <DetailsTitle>{salesOrder.DeliverToName}</DetailsTitle>
          <DetailsSubtitle>{salesOrder.Description}</DetailsSubtitle>
          <DetailsRow>
            <DetailsElement>
              <CalendarIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
              Besteldatum {salesOrder.OrderDate}
            </DetailsElement>
            <DetailsElement>
              <CalendarIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
              Leveringsdatum {salesOrder.DeliveryDate}
            </DetailsElement>
          </DetailsRow>
          <DetailsElement>
            {
              (salesOrder.DeliveryStatus === 12) ?
                <StatusTextGreen>Open</StatusTextGreen> :
                (salesOrder.DeliveryStatus === 20) ?
                  <StatusTextOrange>Deels geleverd</StatusTextOrange> :
                  (salesOrder.DeliveryStatus === 21) ?
                    <StatusTextBlack>Afgerond</StatusTextBlack> :
                    (salesOrder.DeliveryStatus === 45) ?
                      <StatusTextRed>Geannuleerd</StatusTextRed> :
                      <StatusTextGray>Status Onbekend</StatusTextGray>
            }
          </DetailsElement>
        </DetailsList>
        <DetailsActions>
          <ActionButton
            action={openCreateReceiptPopup}>
            <ClipboardCheckIcon className="-ml-1 mr-2 h-5 w-5 text-gray-400" aria-hidden="true" />
            Goederen Verzenden
          </ActionButton>
        </DetailsActions>
      </DetailsBox>
      <ListContainer setSourceItems={setSalesOrderLines} rawSourceItems={salesOrder.SalesOrderLines} title='Materiaal lijst' pageSize={5}>
        <List>
          {salesOrderLines.map((salesOrderLine) => (
            <Row
              key={salesOrderLine.ID}
              link={`/item/details/${salesOrderLine.Item.ID}`}>
              <RowLine>
                <RowTitle>{salesOrderLine.Item.Description}</RowTitle>
                <RowElement>
                  <CubeIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                  Product Code: {salesOrderLine.Item.Code}
                </RowElement>
              </RowLine>
              <RowLine>
                <RowElement>Lijnnummer: {salesOrderLine.LineNumber}</RowElement>
                <RowElement>
                  <ClipboardCheckIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                  Delivered: {salesOrderLine.QuantityDelivered}/{salesOrderLine.Quantity} ({salesOrderLine.UnitDescription})
                </RowElement>
              </RowLine>
            </Row>
          ))}
        </List>
      </ListContainer>
    </div >
  );
};

export default SalesOrderDetails;
