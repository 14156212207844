import { combineReducers } from 'redux';
import currentUser from './currentUser';
import notificationState from './notificationState';

const rootReducer = combineReducers({
  currentUser,
  notificationState
});

export default rootReducer;
