import React from 'react';
import { storageMenu } from '../../../static-data/menus';
import Menu from '../../components/Menu';


const StorageMenu = () => {
  return (
    <Menu menuOptions={storageMenu}></Menu>
  );
};

export default StorageMenu;
