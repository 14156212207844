import React from 'react';
import { Link } from 'react-router-dom';


const Menu = ({ menuOptions }) => {
  return (
    <div className="min-h-full">
      <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 mt-10">
        <div className="mt-2 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-2">
          {menuOptions.map((item) => (
            <div key={item.title} className="bg-white overflow-hidden shadow rounded-lg">
              <div className="p-5">
                <div className="flex items-center">
                  <div className="flex-shrink-0">
                    <item.icon className="h-6 w-6 text-blue-400" aria-hidden="true" />
                  </div>
                  <div className="ml-5 w-0 flex-1">
                    <dl>
                      <dd>
                        <div className="text-lg font-medium text-gray-900">{item.title}</div>
                      </dd>
                      <dt className="text-sm font-medium text-gray-500 truncate">{item.description}</dt>
                    </dl>
                  </div>
                </div>
              </div>
              <Link
                to={item.link}>
                <div className="w-full bg-gray-200 px-5 py-3">
                  <p
                    className="font-medium text-blue hover:text-cyan-900">
                    {item.actieTitle}
                  </p>
                </div>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};


export default Menu;
