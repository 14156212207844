import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import Pagination from './Pagination';


const List = ({ children }) => {
  return (
    <ul role="list" className="divide-y divide-gray-200">
      {children}
    </ul>
  );
};


const ListContainer = ({ children, rawSourceItems, setSourceItems, title = null, pageSize = 10 }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pages, setPages] = useState(0);

  useEffect(() => {
    setCurrentPage(1);
    setPages(Math.ceil(parseFloat(rawSourceItems.length) / parseFloat(pageSize)));
  }, [rawSourceItems]);

  useEffect(() => {
    setSourceItems(rawSourceItems.slice(((currentPage - 1) * pageSize), (currentPage * pageSize)));
  }, [pages, currentPage, rawSourceItems]);

  return (
    <div className="m-2">
      <div className="max-w-7xl mx-auto overflow-hidden">
        {(title !== null) &&
          <h1 className="text-1xl font-bold leading-7 text-gray-900 sm:text-2xl sm:truncate">
            {title}
          </h1>
        }
      </div>
      <div className="max-w-7xl mx-auto mt-2 bg-white shadow overflow-hidden sm:rounded-md">
        <ListHeader>
          <p
            className='inline-flex text-sm font-medium text-gray-300'>
            {(rawSourceItems.length === 0) ?
              <span>Geen resultaten</span> :
              <span>{rawSourceItems.length} resultaten</span>
            }
          </p>
        </ListHeader>
        {children}
        <Pagination pages={pages} currentPage={currentPage} setCurrentPage={setCurrentPage}></Pagination>
      </div>
    </div >
  );
};

const ListHeader = ({ children }) => {
  return (
    <div className="col-start-1 row-start-1 py-4 bg-blue-500">
      <div className="flex justify-between max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {children}
      </div>
    </div>
  );
};

const Row = ({ link=null, children }) => {
  if (link) {
    return (
      <li>
        <Link
          to={link}
          className="block hover:bg-gray-50">
          <div className="px-4 py-4 sm:px-6">
            {children}
          </div>
        </Link>
      </li>
    );
  } else {
    return (
      <li>
        <div
          className="block hover:bg-gray-50">
          <div className="px-4 py-4 sm:px-6">
            {children}
          </div>
        </div>
      </li>
    );
  }
};

const RowElement = ({ children }) => {
  return (
    <div className="sm:flex sm:justify-between">
      <p className="flex items-center text-sm text-gray-500">
        {children}
      </p>
    </div>
  );
};

const RowLine = ({ children }) => {
  return (
    <div className="sm:flex sm:justify-between">
      {children}
    </div>

  );
};

const RowTitle = ({ children }) => {
  return (
    <div className="sm:flex sm:justify-between">
      <p className="text-sm font-medium text-blue truncate">
        {children}
      </p>
    </div>
  );
};

export { RowLine, RowTitle, RowElement, List, ListHeader, ListContainer, Row };
