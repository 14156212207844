import {
  CalculatorIcon, CalendarIcon, ClipboardCheckIcon, CollectionIcon, CubeIcon, LinkIcon, QrcodeIcon
} from '@heroicons/react/solid';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAxios } from '../../../helpers/AxiosContext';
import Loading from '../../general/Loading';
import { DetailsBox, DetailsElement, DetailsList, DetailsRow, DetailsSubtitle, DetailsTitle } from '../../widgets/DetailComponents';
import { List, ListContainer, ListHeader, Row, RowElement, RowLine, RowTitle } from '../../widgets/ListComponents';


const ItemDetail = () => {
  const { id } = useParams();
  const [isLoading, setLoading] = useState(true);
  const [item, setItem] = useState({});
  const [locations, setLocations] = useState([]);
  const [purchaseOrderLines, setPurchaseOrderLines] = useState([]);
  const [goodsReceiptLines, setGoodsReceiptLines] = useState([]);
  const [goodsDeliveryLines, setGoodsDeliveryLines] = useState([]);
  const [shopOrderReceipts, setShopOrderReceipts] = useState([]);
  const [salesOrderLines, setSalesOrderLines] = useState([]);
  const [shopOrders, setShopOrders] = useState([]);
  const axiosClient = useAxios();
  const [serialCode, setSerialCode] = useState([]);
  const [batchCode, setBatchCode] = useState([]);
  const [searchResults, setSearchResults] = useState([]);

  useEffect(() => {
    axiosClient.get(`api/item/get_item/${id}`)
      .then((response) => {
        console.log(response.data);
        setItem(response.data);
      })
      .catch((error) => {
        console.log(`Error ${error}`);
      }).finally(() => {
        setLoading(false);
      });
  }, [id]);

  const searchSerialCode = (event) => {
    event.preventDefault();
    axiosClient.get(`api/item/search_serial_number/${id}/${serialCode}`)
      .then((response) => {
        console.log(response.data);
        const searchResult = response.data;
        searchResult['searchcode'] = serialCode;
        setSearchResults([searchResult]);
      })
      .catch((error) => {
        console.log(`Error ${error}`);
      }).finally(() => {
        setLoading(false);
      });
  };

  const searchBatchCode = (event) => {
    event.preventDefault();
    axiosClient.get(`api/item/search_batch_number/${id}/${batchCode}`)
      .then((response) => {
        console.log(response.data);
      })
      .catch((error) => {
        console.log(`Error ${error}`);
      }).finally(() => {
        setLoading(false);
      });
  };

  if (isLoading) {
    return (
      <Loading></Loading>
    );
  }

  return (
    <div className="min-h-full">
      <DetailsBox>
        <DetailsList>
          <DetailsSubtitle>
            <CollectionIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
            Item Groep: {item.ItemGroupDescription}
          </DetailsSubtitle>
          <DetailsTitle>{item.Description}</DetailsTitle>
          <DetailsSubtitle>
            <QrcodeIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
            Barcode: {item.Barcode}
          </DetailsSubtitle>
          <DetailsRow>
            <DetailsElement>
              <CubeIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
              Exact Code: {item.Code}
            </DetailsElement>
            <DetailsElement>
              Categorie: {item.Description_01 ? <span>&nbsp;{item.Description_01}</span> : <span>&nbsp;None</span>}
            </DetailsElement>
          </DetailsRow>
          <DetailsRow>
            <DetailsElement>
              Sub-Categorie: {item.Description_02 ? <span>&nbsp;{item.Description_02}</span> : <span>&nbsp;None</span>}
            </DetailsElement>
            <DetailsElement>
              Sub-Sub-Categorie: {item.Description_03 ? <span>&nbsp;{item.Description_03}</span> : <span>&nbsp;None</span>}
            </DetailsElement>
          </DetailsRow>
          <DetailsRow>
            <DetailsElement>
              <CalculatorIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
              Voorraad: {item.Stock} ({item.UnitDescription})
            </DetailsElement>
          </DetailsRow>
          <DetailsRow>
            {item.IsSerialItem &&
              <form onSubmit={searchSerialCode} className="space-y-6">
                <div>
                  <label htmlFor="number" className="block text-sm font-medium text-gray-700">
                    Zoek doormiddel van serienummer:
                  </label>
                  <div className="mt-1">
                    <input
                      id="serialcode"
                      name="serialcode"
                      type="text"
                      value={serialCode}
                      onChange={(e) => setSerialCode(e.target.value)}
                      className="
                            appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md
                            shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"/>
                  </div>
                </div>
                <div>
                  <button
                    type="submit"
                    className="
                          w-full flex justify-center py-2 px-4 border border-transparent rounded-md
                          shadow-sm text-sm font-medium text-white bg-blue hover:bg-blue-400 focus:outline-none
                          focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                    Zoek
                  </button>
                </div>
              </form>
            }
            {item.IsBatchItem &&
              <form onSubmit={searchBatchCode} className="space-y-6">
                <div>
                  <label htmlFor="number" className="block text-sm font-medium text-gray-700">
                    Zoek doormiddel van batchnummer:
                  </label>
                  <div className="mt-1">
                    <input
                      id="batchcode"
                      name="batchcode"
                      type="text"
                      value={batchCode}
                      onChange={(e) => setBatchCode(e.target.value)}
                      className="
                            appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md
                            shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"/>
                  </div>
                </div>
                <div>
                  <button
                    type="submit"
                    className="
                          w-full flex justify-center py-2 px-4 border border-transparent rounded-md
                          shadow-sm text-sm font-medium text-white bg-blue hover:bg-blue-400 focus:outline-none
                          focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                    Zoek
                  </button>
                </div>
              </form>
            }
          </DetailsRow>
        </DetailsList>
        {searchResults.map((searchResult, index) => (
          <div
            key={index}>
            <div className="max-w-7xl mx-auto overflow-hidden">
              <h1 className="text-1xl font-bold leading-7 text-gray-900 sm:text-2xl sm:truncate">
                {searchResult.searchcode}
              </h1>
            </div>
            <div className="m-2">
              <div className="max-w-7xl mx-auto mt-2 bg-white shadow overflow-hidden sm:rounded-md">
                <ListHeader>
                  <p
                    className='inline-flex text-sm font-medium text-gray-300'>
                    Verzendbonnen
                  </p>
                </ListHeader>
                <List>
                  {searchResult.goodsdeliverylines.map((goodsdeliveryline) => (
                    <Row
                      key={goodsdeliveryline.ID}
                      link={`/goodsdeliveryline/details/${goodsdeliveryline.ID}`}>
                      <RowLine>
                        <RowTitle>{goodsdeliveryline.Description}</RowTitle>
                        <RowElement>Verzendnummer: {goodsdeliveryline.SalesOrderNumber}</RowElement>
                      </RowLine>
                      <RowLine>
                        <RowElement>Leveringsnummer: {goodsdeliveryline.GoodsDeliveryNumber}</RowElement>
                        <RowElement>Lijnummer: {goodsdeliveryline.LineNumber}</RowElement>
                      </RowLine>
                      <RowElement>
                        <ClipboardCheckIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                        {goodsdeliveryline.QuantityDelivered}/{goodsdeliveryline.QuantityOrdered} ({goodsdeliveryline.UnitDescription})
                        <CalendarIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                        Verzenddatum {goodsdeliveryline.DeliveryDate}
                      </RowElement>
                    </Row>
                  ))}
                </List>
              </div>
            </div>
            <div className="m-2">
              <div className="max-w-7xl mx-auto mt-2 bg-white shadow overflow-hidden sm:rounded-md">
                <ListHeader>
                  <p
                    className='inline-flex text-sm font-medium text-gray-300'>
                    Ontvangstbonnen
                  </p>
                </ListHeader>
                <List>
                  {searchResult.goodsreceiptlines.map((goodsreceiptline) => (
                    <Row
                      key={goodsreceiptline.ID}
                      link={`/goodsreceiptLine/details/${goodsreceiptline.ID}`}>
                      <RowLine>
                        <RowTitle>Bestelnummer: {goodsreceiptline.PurchaseOrderNumber}</RowTitle>
                        <RowElement>Lijnnummer: {goodsreceiptline.LineNumber}</RowElement>
                      </RowLine>
                      <RowLine>
                        <RowElement>
                          <ClipboardCheckIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                          {goodsreceiptline.QuantityReceived}/{goodsreceiptline.QuantityOrdered} ({goodsreceiptline.UnitDescription})
                        </RowElement>
                        <RowElement>
                          <LinkIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                          Referentie:
                          {(goodsreceiptline.SupplierItemCode) ?
                            <span>&nbsp; {goodsreceiptline.SupplierItemCode}</span> :
                            <span>&nbsp; None</span>}
                        </RowElement>
                      </RowLine>
                      <RowLine>
                        <RowElement>
                          <CalendarIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                          Leveringsdatum {goodsreceiptline.Created}
                        </RowElement>
                      </RowLine>
                    </Row>
                  ))}
                </List>
              </div>
            </div>
            <div className="m-2">
              <div className="max-w-7xl mx-auto mt-2 bg-white shadow overflow-hidden sm:rounded-md">
                <ListHeader>
                  <p
                    className='inline-flex text-sm font-medium text-gray-300'>
                    Assemblage waarin maak product
                  </p>
                </ListHeader>
                <List>
          {searchResult.own_shoporderreceipts.map((ownShoporderreceipt) => (
            <Row
              key={ownShoporderreceipt.ID}
              link={`/shoporderreceipt/details/${ownShoporderreceipt.ID}`}>
              <RowLine>
                <RowTitle>Assemblagenummer: {ownShoporderreceipt.ShopOrderNumber}</RowTitle>
                <RowElement>Aantal: {ownShoporderreceipt.Quantity}</RowElement>
              </RowLine>
              <RowLine>
                <RowElement>
                  <CalendarIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                  Verwerkingsdatum {ownShoporderreceipt.Created}
                </RowElement>
              </RowLine>
            </Row>
          ))}
        </List>
              </div>
            </div>
            <div className="m-2">
              <div className="max-w-7xl mx-auto mt-2 bg-white shadow overflow-hidden sm:rounded-md">
                <ListHeader>
                  <p
                    className='inline-flex text-sm font-medium text-gray-300'>
                    Assemblage waarin onderdeel
                  </p>
                </ListHeader>
                <List>
          {searchResult.parent_shoporderreceipts.map((parentShoporderreceipt) => (
            <Row
              key={parentShoporderreceipt.ID}
              link={`/shoporderreceipt/details/${parentShoporderreceipt.ID}`}>
              <RowLine>
                <RowTitle>Assemblagenummer: {parentShoporderreceipt.ShopOrderNumber}</RowTitle>
                <RowElement>Aantal: {parentShoporderreceipt.Quantity}</RowElement>
              </RowLine>
              <RowLine>
                <RowElement>
                  <CalendarIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                  Verwerkingsdatum {parentShoporderreceipt.Created}
                </RowElement>
              </RowLine>
            </Row>
          ))}
        </List>
              </div>
            </div>
          </div>
        ))
        }
      </DetailsBox >
      <ListContainer setSourceItems={setLocations} rawSourceItems={item.Locations} title='Locaties Item' pageSize={5}>
        <List>
          {locations.map((location) => (
            <Row
              key={location.ID}
              link={`/location/details/${location.ID}`}>
              <RowLine>
                <RowTitle>{location.Description}</RowTitle>
                <RowElement>Opslagnummer: {location.StorageNumber}</RowElement>
              </RowLine>
            </Row>
          ))}
        </List>
      </ListContainer>
      <ListContainer setSourceItems={setPurchaseOrderLines} rawSourceItems={item.PurchaseOrderLines} title='Bestellingen' pageSize={5}>
        <List>
          {purchaseOrderLines.map((purchaseOrderLine) => (
            <Row
              key={purchaseOrderLine.ID}
              link={`/purchaseorder/details/${purchaseOrderLine.PurchaseOrderID}`}>
              <RowLine>
                <RowTitle>Bestelnummer: {purchaseOrderLine.PurchaseOrderNumber}</RowTitle>
                <RowElement>Lijnnummer: {purchaseOrderLine.LineNumber}</RowElement>
              </RowLine>
              <RowLine>
                <RowElement>
                  <ClipboardCheckIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                  {purchaseOrderLine.ReceivedQuantity}/{purchaseOrderLine.QuantityInPurchaseUnits} ({purchaseOrderLine.UnitDescription}({purchaseOrderLine.QuantityInUnit}))
                </RowElement>
                <RowElement>
                  <LinkIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                  Referentie:
                  {(purchaseOrderLine.SupplierItemCode) ?
                    <span>&nbsp; {purchaseOrderLine.SupplierItemCode}</span> :
                    <span>&nbsp; None</span>}
                </RowElement>
              </RowLine>
            </Row>
          ))}
        </List>
      </ListContainer>
      <ListContainer setSourceItems={setSalesOrderLines} rawSourceItems={item.SalesOrderLines} title='Verzendingen' pageSize={5}>
        <List>
          {salesOrderLines.map((salesOrderLine) => (
            <Row
              key={salesOrderLine.ID}
              link={`/salesorder/details/${salesOrderLine.SalesOrderID}`}>
              <RowLine>
                <RowTitle>Verzendnummer: {salesOrderLine.SalesOrderNumber}</RowTitle>
                <RowElement>Lijnnummer: {salesOrderLine.LineNumber}</RowElement>
              </RowLine>
              <RowLine>
                <RowElement>
                  <ClipboardCheckIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                  Delivered: {salesOrderLine.QuantityDelivered}/{salesOrderLine.Quantity} ({salesOrderLine.UnitDescription})
                </RowElement>
              </RowLine>
            </Row>
          ))}
        </List>
      </ListContainer>
      <ListContainer setSourceItems={setShopOrders} rawSourceItems={item.ShopOrders} title='Werkorders' pageSize={5}>
        <List>
          {shopOrders.map((shopOrder) => (
            <Row
              key={shopOrder.ID}
              link={`/shoporder/details/${shopOrder.ID}`}>
              <RowLine>
                <RowTitle>Assemblagenummer: {shopOrder.shopOrderNumber}</RowTitle>
                <RowElement>
                  <ClipboardCheckIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                  {shopOrder.ProducedQuantity}/{shopOrder.PlannedQuantity}
                </RowElement>
              </RowLine>
              <RowLine>
                <RowElement>
                  <CalendarIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                  Startdatum {shopOrder.PlannedStartDate}
                </RowElement>
                <RowElement>
                  <CalendarIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                  Einddatum {shopOrder.PlannedDate}
                </RowElement>
              </RowLine>
            </Row>
          ))}
        </List>
      </ListContainer>
      <ListContainer setSourceItems={setGoodsReceiptLines} rawSourceItems={item.GoodsReceiptLines} title='Ontvangstbonnen' pageSize={5}>
        <List>
          {goodsReceiptLines.map((goodsReceiptLine) => (
            <Row
              key={goodsReceiptLine.ID}
              link={`/goodsreceiptLine/details/${goodsReceiptLine.ID}`}>
              <RowLine>
                <RowTitle>Bestelnummer: {goodsReceiptLine.PurchaseOrderNumber}</RowTitle>
                <RowElement>Lijnnummer: {goodsReceiptLine.LineNumber}</RowElement>
              </RowLine>
              <RowLine>
                <RowElement>
                  <ClipboardCheckIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                  {goodsReceiptLine.QuantityReceived}/{goodsReceiptLine.QuantityOrdered} ({goodsReceiptLine.UnitDescription})
                </RowElement>
                <RowElement>
                  <LinkIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                  Referentie:
                  {(goodsReceiptLine.SupplierItemCode) ?
                    <span>&nbsp; {goodsReceiptLine.SupplierItemCode}</span> :
                    <span>&nbsp; None</span>}
                </RowElement>
              </RowLine>
              <RowLine>
                <RowElement>
                  <CalendarIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                  Leveringsdatum {goodsReceiptLine.Created}
                </RowElement>
              </RowLine>
            </Row>
          ))}
        </List>
      </ListContainer>
      <ListContainer setSourceItems={setGoodsDeliveryLines} rawSourceItems={item.GoodsDeliveryLines} title='Verkoopbonnen' pageSize={5}>
        <List>
          {goodsDeliveryLines.map((goodsDeliveryLine) => (
            <Row
              key={goodsDeliveryLine.ID}
              link={`/goodsdeliveryline/details/${goodsDeliveryLine.ID}`}>
              <RowLine>
                <RowTitle>Verzendnummer: {goodsDeliveryLine.SalesOrderNumber}</RowTitle>
                <RowElement>Lijnnummer: {goodsDeliveryLine.LineNumber}</RowElement>
              </RowLine>
              <RowLine>
                <RowElement>
                  <ClipboardCheckIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                  {goodsDeliveryLine.QuantityDelivered}/{goodsDeliveryLine.QuantityOrdered} ({goodsDeliveryLine.UnitDescription})
                </RowElement>
                <RowElement>
                  <CalendarIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                  Leveringsdatum {goodsDeliveryLine.DeliveryDate}
                </RowElement>
              </RowLine>
            </Row>
          ))}
        </List>
      </ListContainer>
      <ListContainer setSourceItems={setShopOrderReceipts} rawSourceItems={item.ShopOrderReceipts} title='Werkbonnen' pageSize={5}>
        <List>
          {shopOrderReceipts.map((shopOrderReceipt) => (
            <Row
              key={shopOrderReceipt.ID}
              link={`/shoporderreceipt/details/${shopOrderReceipt.ID}`}>
              <RowLine>
                <RowTitle>Assemblagenummer: {shopOrderReceipt.ShopOrderNumber}</RowTitle>
                <RowElement>Aantal: {shopOrderReceipt.Quantity}</RowElement>
              </RowLine>
              <RowLine>
                <RowElement>
                  <CalendarIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                  Verwerkingsdatum {shopOrderReceipt.Created}
                </RowElement>
              </RowLine>
            </Row>
          ))}
        </List>
      </ListContainer>
    </div >
  );
};

export default ItemDetail;
