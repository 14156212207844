import React, { Fragment, useEffect } from 'react';
import { XIcon } from '@heroicons/react/solid';
import { useState } from 'react';
import Loading from '../../general/Loading';
import { useAxios } from '../../../helpers/AxiosContext';
import { Dialog, Transition } from '@headlessui/react';
import { PlusCircleIcon, TrashIcon } from '@heroicons/react/outline';
import ConfirmPopup from '../../widgets/ConfirmPopup';
import { RowElement, Row, RowLine, RowTitle, List, ListContainer } from '../../widgets/ListComponents';
import { StatusTextGreen } from '../../widgets/StatusText';


const StorageList = () => {
  const [isLoading, setLoading] = useState(true);
  const [rawStorages, setRawStorages] = useState([]);
  const [storages, setStorages] = useState([]);
  const axiosClient = useAxios();
  const [showCreate, setShowCreate] = useState(false);
  const [createName, setCreateName] = useState('');
  const [createNumber, setCreateNumber] = useState('');
  const [openConfirm, setOpenConfirm] = useState(false);
  const [storageDeleteID, setStorageDeleteID] = useState('');

  const getStorages = () => {
    setLoading(true);
    axiosClient.get('api/storage/get_storages')
      .then((response) => {
        console.log(response.data);
        setRawStorages(response.data);
      })
      .catch((error) => {
        console.log(`Error ${error}`);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getStorages();
  }, []);

  if (isLoading) {
    return (
      <Loading></Loading>
    );
  }

  const createStorage = (event) => {
    event.preventDefault();
    axiosClient.post('api/storage/create_storage', { 'name': createName, 'number': createNumber })
      .then((response) => {
        console.log(response.data);
        getStorages();
        setShowCreate(false);
      })
      .catch((error) => {
        console.log(`Error ${error}`);
      });
  };

  const setConfirmDelete = () => {
    setLoading(true);
    axiosClient.post(`api/storage/delete_storage/${storageDeleteID}`)
      .then((response) => {
        console.log(response.data);
        getStorages();
      })
      .catch((error) => {
        console.log(`Error ${error}`);
      });
  };

  return (
    <div className="m-2">
      <div className="max-w-7xl mx-auto mt-5 bg-white shadow overflow-hidden sm:rounded-md">
        <ConfirmPopup setConfirm={setConfirmDelete} setOpenConfirm={setOpenConfirm} openConfirm={openConfirm}></ConfirmPopup>
        <div className="col-start-1 row-start-1 py-4 bg-blue-500">
          <div className="flex justify-end max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <button
              onClick={() => setShowCreate(true)}
              className='inline-flex justify-center text-sm font-medium text-gray-300 hover:text-gray-100'>
              <PlusCircleIcon
                className="flex-shrink-0 mr-1 l-1 h-5 w-5 text-gray-300 group-hover:text-gray-100"
                aria-hidden="true"
              />
              Create new storage
            </button>
          </div>

          <Transition.Root show={showCreate} as={Fragment}>
            <Dialog as="div" className="relative z-20" onClose={setShowCreate}>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0">
                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
              </Transition.Child>

              <div className="fixed z-20 inset-0 overflow-y-auto">
                <div className="flex items-center justify-center min-h-full text-center p-0">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                    <Dialog.Panel
                      className="
                        relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden
                        shadow-xl transform transition-all sm:my-8 sm:max-w-xl sm:w-full sm:p-6">
                      <div className="bg-blue-700 py-6 px-4 sm:px-6">
                        <div className="flex items-center justify-between">
                          <Dialog.Title className="text-lg font-medium text-white">Filter Assemblages</Dialog.Title>
                          <div className="ml-3 flex h-7 items-center">
                            <button
                              type="button"
                              className="
                                rounded-md bg-blue-700 text-blue-200 hover:text-white
                                focus:outline-none focus:ring-2 focus:ring-white"
                              onClick={() => setShowCreate(false)}>
                              <span className="sr-only">Sluit</span>
                              <XIcon className="h-6 w-6" aria-hidden="true" />
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="mt-5 sm:mt-6">
                        <form onSubmit={createStorage} className="space-y-6">
                          <div>
                            <label htmlFor="item" className="block text-sm font-medium text-gray-700">
                              Storage Name
                            </label>
                            <div className="mt-1">
                              <input
                                id="item"
                                name="item"
                                type="text"
                                value={createName}
                                onChange={(e) => setCreateName(e.target.value)}
                                required
                                className="
                                  appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md
                                  shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"/>
                            </div>
                            <label htmlFor="item" className="block text-sm font-medium text-gray-700">
                              Storage Number
                            </label>
                            <div className="mt-1">
                              <input
                                id="createnumber"
                                name="createnumber"
                                type="number"
                                value={createNumber}
                                onChange={(e) => setCreateNumber(e.target.value)}
                                required
                                className="
                                  appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md
                                  shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"/>
                            </div>
                          </div>
                          <div>
                            <button
                              type="submit"
                              className="
                                w-full flex justify-center py-2 px-4 border border-transparent rounded-md
                                shadow-sm text-sm font-medium text-white bg-blue hover:bg-blue-400 focus:outline-none
                                focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                              Create
                            </button>
                          </div>
                        </form>
                      </div>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </Dialog>
          </Transition.Root>
        </div>
        <ListContainer rawSourceItems={rawStorages} setSourceItems={setStorages}>
          <List>
            {storages.map((storage) => (
              <Row
                key={storage.ID}
                link={`/storage/details/${storage.ID}`}>
                <RowLine>
                  <RowTitle>{storage.Name}</RowTitle>
                  <RowElement>
                    <button
                      onClick={(event) => {
                        event.preventDefault();
                        setStorageDeleteID(storage.ID);
                        setOpenConfirm(true);
                      }}
                      className="flex z-20">
                      <TrashIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                    </button>
                  </RowElement>
                </RowLine>
                <RowLine>
                  <RowElement>Opslagnummer: {storage.Number}</RowElement>
                  {(storage.Default === true) &&
                    <RowElement>
                      <StatusTextGreen>Default opslag</StatusTextGreen>
                    </RowElement>
                  }
                </RowLine>
              </Row>
            ))}
          </List>
        </ListContainer>
      </div>
    </div>
  );
};

export default StorageList;
