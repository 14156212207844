import React from 'react';

const StatusTextGreen = ({ children }) => {
  return (
    <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-500 text-green-900">
      { children }
    </span>
  );
};

const StatusTextRed = ({ children }) => {
  return (
    <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-500 text-red-900">
      { children }
    </span>
  );
};

const StatusTextOrange = ({ children }) => {
  return (
    <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-orange-500 text-orange-900">
      { children }
    </span>
  );
};

const StatusTextGray = ({ children }) => {
  return (
    <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-gray-500 text-gray-900">
      { children }
    </span>
  );
};

const StatusTextBlack = ({ children }) => {
  return (
    <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-black text-gray-300">
      { children }
    </span>
  );
};

export { StatusTextGreen, StatusTextRed, StatusTextOrange, StatusTextGray, StatusTextBlack };
