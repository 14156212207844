import React, { useEffect } from 'react';
import { CalendarIcon } from '@heroicons/react/solid';
import { useState } from 'react';
import Loading from '../../general/Loading';
import { useAxios } from '../../../helpers/AxiosContext';
import { List, ListContainer, Row, RowElement, RowLine, RowTitle } from '../../widgets/ListComponents';

const GoodsDeliveryList = () => {
  const [isLoading, setLoading] = useState(true);
  const [rawGoodsDeliveries, setRawGoodsDeliveries] = useState([]);
  const [goodsDeliveries, setGoodsDeliveries] = useState([]);
  const axiosClient = useAxios();

  useEffect(() => {
    axiosClient.get('api/salesorder/get_goodsdeliveries')
      .then((response) => {
        console.log(response.data);
        setRawGoodsDeliveries(response.data);
      })
      .catch((error) => {
        console.log(`Error ${error}`);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  if (isLoading) {
    return (
      <Loading></Loading>
    );
  }

  return (
    <ListContainer setSourceItems={setGoodsDeliveries} rawSourceItems={rawGoodsDeliveries} pageSize={5}>
      <List>
        {goodsDeliveries.map((goodsDelivery) => (
          <Row
            key={goodsDelivery.ID}
            link={`/goodsdelivery/details/${goodsDelivery.ID}`}>
            <RowLine>
              <RowTitle>{goodsDelivery.DeliveryAccountName}</RowTitle>
              <RowElement>Order: {goodsDelivery.DeliveryNumber}</RowElement>
            </RowLine>
            <RowLine>
              <RowElement>
                {(goodsDelivery.Description) ?
                  <span>{goodsDelivery.Description}</span> :
                  <span>Geen beschrijving</span>}
              </RowElement>
              <RowElement>
                <CalendarIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                Leveringsdatum {goodsDelivery.DeliveryDate}
              </RowElement>
            </RowLine>
          </Row>
        ))}
      </List>
    </ListContainer>
  );
};

export default GoodsDeliveryList;
