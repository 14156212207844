const setError = (messageObj) => {
  return {
    type: 'SET_ERROR',
    payload: messageObj
  };
};

const setInfo = (messageObj) => {
  return {
    type: 'SET_INFO',
    payload: messageObj
  };
};

const setWarning = (messageObj) => {
  return {
    type: 'SET_WARNING',
    payload: messageObj
  };
};

const setSuccess = (messageObj) => {
  return {
    type: 'SET_SUCCESS',
    payload: messageObj
  };
};

const clearError = () => {
  return {
    type: 'CLEAR_ERROR'
  };
};

const clearInfo = () => {
  return {
    type: 'CLEAR_INFO'
  };
};

const clearWarning = () => {
  return {
    type: 'CLEAR_WARNING'
  };
};

const clearSuccess = () => {
  return {
    type: 'CLEAR_SUCCESS'
  };
};

export default {
    setError,
    setInfo,
    setWarning,
    setSuccess,
    clearError,
    clearInfo,
    clearWarning,
    clearSuccess
};
