import {
  CalendarIcon, ClipboardCheckIcon,
  CubeIcon, LinkIcon, PrinterIcon
} from '@heroicons/react/solid';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAxios } from '../../../helpers/AxiosContext';
import Loading from '../../general/Loading';
import { DetailsActions, DetailsBox, DetailsElement, DetailsList, DetailsRow, DetailsSubtitle, DetailsTitle } from '../../widgets/DetailComponents';
import { ActionButton, LinkButton } from '../../widgets/FancyButtons';
import { List, ListContainer, Row, RowElement, RowLine, RowTitle } from '../../widgets/ListComponents';


const GoodsDeliveryDetails = () => {
  const { id } = useParams();
  const [isLoading, setLoading] = useState(true);
  const [goodsDelivery, setGoodsDelivery] = useState({});
  const [goodsDeliveryLines, setGoodsDeliveryLines] = useState([]);
  const axiosClient = useAxios();

  useEffect(() => {
    axiosClient.get(`api/salesorder/get_goodsdelivery/${id}`)
      .then((response) => {
        console.log(response.data);
        setGoodsDelivery(response.data);
      })
      .catch((error) => {
        console.log(`Error ${error}`);
      }).finally(() => {
        setLoading(false);
      });
  }, [id]);


  const printPakbon = () => {
    setLoading(true);
    axiosClient.get(`api/printing/packingslip/${id}`, { responseType: 'blob' })
      .then((response) => {
        console.log(response.data);
        const file = new Blob([response.data], { type: 'application/pdf' });
        saveAs(file, `pakbon-${id}.pdf`);
      })
      .catch((error) => {
        console.log(`Error ${error}`);
      }).finally(() => {
        setLoading(false);
      });
  };


  if (isLoading) {
    return (
      <Loading></Loading>
    );
  }

  return (
    <div className="min-h-full">
      <DetailsBox>
        <DetailsList>
          <DetailsSubtitle>Verzendnummer: {goodsDelivery.DeliveryNumber}</DetailsSubtitle>
          <DetailsTitle>{goodsDelivery.DeliveryAccountName}</DetailsTitle>
          <DetailsSubtitle>{goodsDelivery.Description}</DetailsSubtitle>
          <DetailsRow>
            <DetailsElement>
              <CalendarIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
              Leveringsdatum {goodsDelivery.DeliveryDate}
            </DetailsElement>
            <DetailsElement>
              Verkoopnummer: {goodsDelivery.SalesOrderNumbers}
            </DetailsElement>
          </DetailsRow>
          <DetailsRow>
            <DetailsElement>
              <CalendarIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
              Trackingnummer: {goodsDelivery.TrackingNumber}
            </DetailsElement>
          </DetailsRow>
        </DetailsList>
        <DetailsActions>
          <ActionButton
            action={printPakbon}>
            <PrinterIcon className="-ml-1 mr-2 h-5 w-5 text-gray-400" aria-hidden="true" />
            Pakbon
          </ActionButton>
          {(goodsDelivery.SalesOrderIDs.length === 1) ?
            <LinkButton url={`/salesorder/details/${goodsDelivery.SalesOrderIDs[0]}`}>
              <LinkIcon className="-ml-1 mr-2 h-5 w-5 text-gray-400" aria-hidden="true" />
              Bekijk verzending
            </LinkButton> :
            <DetailsSubtitle>Verzending bestaat uit meerdere verkooporders</DetailsSubtitle>}
        </DetailsActions>
      </DetailsBox>
      <ListContainer setSourceItems={setGoodsDeliveryLines} rawSourceItems={goodsDelivery.GoodsDeliveryLines} title='Materiaal lijst' pageSize={5}>
        <List>
          {goodsDeliveryLines.map((goodsDeliveryLine) => (
            <Row
              key={goodsDeliveryLine.ID}
              link={`/item/details/${goodsDeliveryLine.Item.ID}`}>
              <RowLine>
                <RowTitle>{goodsDeliveryLine.Description}</RowTitle>
                <RowElement>Lijnnummer: {goodsDeliveryLine.LineNumber}</RowElement>
              </RowLine>
              <RowLine>
                <RowElement>
                  <CubeIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                  Artikel {goodsDeliveryLine.Item.Code}
                </RowElement>
                <RowElement>
                  <ClipboardCheckIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                  {goodsDeliveryLine.QuantityDelivered}/{goodsDeliveryLine.QuantityOrdered} ({goodsDeliveryLine.UnitDescription})
                </RowElement>
              </RowLine>
            </Row>
          ))}
        </List>
      </ListContainer>
    </div>
  );
};

export default GoodsDeliveryDetails;
