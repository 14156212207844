import {
  CalendarIcon, ClipboardCheckIcon,
  CubeIcon, LinkIcon, QrcodeIcon
} from '@heroicons/react/solid';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAxios } from '../../../helpers/AxiosContext';
import Loading from '../../general/Loading';
import { ActionButton } from '../../widgets/FancyButtons';
import { DetailsActions, DetailsBox, DetailsElement, DetailsList, DetailsRow, DetailsSubtitle, DetailsTitle } from '../../widgets/DetailComponents';
import { List, ListContainer, Row, RowElement, RowLine, RowTitle } from '../../widgets/ListComponents';
import { StatusTextBlack, StatusTextGray, StatusTextGreen, StatusTextOrange, StatusTextRed } from '../../widgets/StatusText';
import { LineBatch, LineRegular, LineSerial } from '../../components/ReceiptPopup';
import PopupWindow from '../../widgets/PopupWindow';


const PurchaseOrderDetails = () => {
  const { id } = useParams();
  const [isLoading, setLoading] = useState(true);
  const [purchaseorder, setPurchaseorder] = useState({});
  const [purchaseOrderLines, setPurchaseOrderLines] = useState([]);
  const axiosClient = useAxios();
  const [receiptData, setReceiptData] = useState([]);
  const [openReceiptPopup, setOpenReceiptPopup] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    axiosClient.get(`api/purchaseorder/get_purchaseorder/${id}`)
      .then((response) => {
        console.log(response.data);
        setPurchaseorder(response.data);
      })
      .catch((error) => {
        console.log(`Error ${error}`);
      }).finally(() => {
        setLoading(false);
      });
  }, [id]);

  const openCreateReceiptPopup = () => {
    const data = purchaseorder.PurchaseOrderLines.map((x) => {
      x['value'] = x['Quantity'] - x['ReceivedQuantity'];
      x['batchnumbers'] = [];
      x['serialnumbers'] = [];
      return x;
    });
    setReceiptData(data);
    setOpenReceiptPopup(true);
  };

  const submitReceipt = () => {
    console.log(receiptData);
    axiosClient.post('api/purchaseorder/process_purchaseorder', { 'GoodsReceiptLines': receiptData, 'PurchaseOrderID': id })
      .then((response) => {
        console.log(response.data);
        navigate(`/goodsreceipt/details/${response.data}`);
      })
      .catch((error) => {
        setOpenReceiptPopup(true);
        console.log(`Error ${error}`);
        setLoading(false);
      });
  };

  if (isLoading) {
    return (
      <Loading></Loading>
    );
  }

  return (
    <div className="min-h-full">
      <DetailsBox>
        <PopupWindow setOpen={setOpenReceiptPopup} open={openReceiptPopup} submitForm={submitReceipt} title='Goederen ontangen'>
          {receiptData.map((line, index) => (
            <div
              key={line.ID}>
              <p className="flex items-center text-sm font-medium text-gray-700">
                {line.Item.Description}
              </p>
              <p className="flex items-center text-sm text-gray-500">
                Al ontvangen:
                <span className='font-medium text-gray-700'>
                  &nbsp;{line.ReceivedQuantity}/{line.Quantity} ({line.UnitDescription}({line.QuantityInUnit}))
                </span>
              </p>
              {(line.Item.Barcode) ?
                <p className="flex items-center text-sm text-gray-500">
                  <QrcodeIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />Barcode intern: &nbsp;
                  <span className='font-medium text-gray-700'>
                    {line.Item.Barcode}
                  </span>
                </p> :
                <p className="flex items-center text-sm text-gray-500">
                  <QrcodeIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />Barcode intern bestaat niet
                </p>
              }
              {(line.SupplierItemCode) ?
                <p className="flex items-center text-sm text-gray-500">
                  <QrcodeIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />Barcode leverancier: &nbsp;
                  <span className='font-medium text-gray-700'>
                    {line.SupplierItemCode}
                  </span>
                </p> :
                <p className="flex items-center text-sm text-gray-500">
                  <QrcodeIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />Barcode leverancier onbekend
                </p>
              }
              {(line.Item.IsSerialItem) ?
                <LineSerial line={line} index={index} lines={receiptData} setLines={setReceiptData}></LineSerial> :
                (line.Item.IsBatchItem) ?
                  <LineBatch line={line} index={index} lines={receiptData} setLines={setReceiptData}></LineBatch> :
                  <LineRegular line={line} index={index} lines={receiptData} setLines={setReceiptData}></LineRegular>
              }
            </div>
          ))}
        </PopupWindow>
        <DetailsList>
          <DetailsSubtitle>Bestelnummer: {purchaseorder.OrderNumber}</DetailsSubtitle>
          <DetailsTitle>{purchaseorder.SupplierName}</DetailsTitle>
          <DetailsSubtitle>{purchaseorder.Description}</DetailsSubtitle>
          <DetailsRow>
            <DetailsElement>
              <CalendarIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
              Besteldatum {purchaseorder.OrderDate}
            </DetailsElement>
            <DetailsElement>
              <CalendarIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
              Leveringsdatum {purchaseorder.ReceiptDate}
            </DetailsElement>
          </DetailsRow>
          <DetailsRow>
            <DetailsElement>
              {(purchaseorder.ReceiptStatus === 10) ?
                <StatusTextGreen>Open</StatusTextGreen> :
                (purchaseorder.ReceiptStatus === 20) ?
                  <StatusTextOrange>Deels ontvangen</StatusTextOrange> :
                  (purchaseorder.ReceiptStatus === 30) ?
                    <StatusTextBlack>Voltooid</StatusTextBlack> :
                    (purchaseorder.ReceiptStatus === 40) ?
                      <StatusTextRed>Geannuleerd</StatusTextRed> :
                      <StatusTextGray>Onbekend</StatusTextGray>
              }
            </DetailsElement>
          </DetailsRow>
        </DetailsList>
        <DetailsActions>
          <ActionButton action={openCreateReceiptPopup}>
            <ClipboardCheckIcon className="-ml-1 mr-2 h-5 w-5 text-gray-400" aria-hidden="true" />
            Goederen Ontvangen
          </ActionButton>
        </DetailsActions>
      </DetailsBox>
      <ListContainer setSourceItems={setPurchaseOrderLines} rawSourceItems={purchaseorder.PurchaseOrderLines} title='Materiaal lijst' pageSize={5}>
        <List>
          {purchaseOrderLines.map((purchaseOrderLine) => (
            <Row
              key={purchaseOrderLine.ID}
              link={`/item/details/${purchaseOrderLine.Item.ID}`}>
              <RowLine>
                <RowTitle>{purchaseOrderLine.Item.Description}</RowTitle>
                <RowElement>
                  <CubeIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                  Artikel {purchaseOrderLine.Item.Code}
                </RowElement>
              </RowLine>
              <RowLine>
                <RowElement>Lijnnummer: {purchaseOrderLine.LineNumber}</RowElement>
                <RowElement>
                  <LinkIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                  Referentie:
                  {purchaseOrderLine.SupplierItemCode ?
                    <span>&nbsp; {purchaseOrderLine.SupplierItemCode}</span> :
                    <span>&nbsp; None</span>}
                </RowElement>
              </RowLine>
              <RowLine>
                <RowElement>
                  <ClipboardCheckIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                  {purchaseOrderLine.ReceivedQuantity}/{purchaseOrderLine.QuantityInPurchaseUnits} ({purchaseOrderLine.UnitDescription}({purchaseOrderLine.QuantityInUnit}))
                </RowElement>
              </RowLine>
            </Row>
          ))}
        </List>
      </ListContainer>
    </div >
  );
};

export default PurchaseOrderDetails;
