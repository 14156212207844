const mainNavigation = [
  { name: 'Home', link: '/' },
  { name: 'Assemblage', link: '/shoporder' },
  { name: 'Ontvangen', link: '/purchaseorder' },
  { name: 'Verzenden', link: '/salesorder' },
  { name: 'Opslag', link: '/storage' },
];

const userNavigation = [
  { name: 'Subscriptions', link: '/subscriptions' },
  { name: 'Exact Omgeving', link: '/devision' }
];

export { mainNavigation, userNavigation };
