import React from 'react';
import PopupWindow from '../widgets/PopupWindow';
import { useEffect } from 'react';
import { useState } from 'react';


const CreateShopReceiptPopup = ({ setOpen, open, data, setData, submitForm, label }) => {
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    setErrorMessage('');
  }, [open]);

  const checkBeforeSubmit = () => {
    try {
      const newData = [...data];
      data.forEach((line) => {
        if (line.IsSerial) {
          if (line.SerialNumber === '') {
            throw new Error('Serie code veld mag niet leeg zijn!');
          }
        }
        if (line.IsBatch) {
          if (line.BatchNumber === '') {
            throw new Error('Batch code veld mag niet leeg zijn!');
          }
        }
      });
      setData(newData);
    } catch {
      setErrorMessage('Serie en batch code velden mogen niet leeg zijn!');
      setOpen(true);
      return;
    }
    submitForm();
  };

  const batchRows = (index) => {
    const rows = [];
    for (let batchIndex = 0; batchIndex < data[index].PlannedQuantityFactor; batchIndex++) {
      rows.push(
        <div
          className="mt-1"
          key={batchIndex}>
          <input
            name="batchnumber"
            type="text"
            value={data[index].BatchNumbers[batchIndex]}
            placeholder='Batchnummer'
            onChange={(e) => {
              const newLines = [...data];
              newLines[index].BatchNumbers[batchIndex] = e.target.value;
              setData(newLines);
            }}
            className="
              appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md
              shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"/>
        </div>
      );
    }
    return rows;
  };

  return (
    <PopupWindow setOpen={setOpen} open={open} submitForm={checkBeforeSubmit} title={label} errorMessage={errorMessage}>
      {data.map((line, index) => (
        <div
          key={index}>
          {line.IsBatch &&
            <div>
              <p className="flex items-center text-sm font-medium text-gray-700">
                {line.Item.Description}
              </p>
              {batchRows(index)}
            </div>
          }
          {line.IsSerial &&
            line.SerialNumbers.map((SerialNumber, serialindex) => (
            <div
                key={index}>
              <p className="flex items-center text-sm font-medium text-gray-700">
                {line.Item.Description}
              </p>
              <div className="mt-1">
                <input
                  name='serialnumber'
                  type="text"
                  value={SerialNumber}
                  placeholder='Serienummer'
                  onChange={(e) => {
                    const newLines = [...data];
                    newLines[index].SerialNumbers[serialindex] = e.target.value;
                    setData(newLines);
                  }}
                  className="
                  appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md
                  shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"/>
              </div>
            </div>
            ))
          }
        </div>
      ))}
    </PopupWindow>
  );
};

export default CreateShopReceiptPopup;
