import React from 'react';
import { createContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import configData from '../config.json';
import allActions from './store/actions';


const AxiosContext = createContext();


const AxiosProvider = ({ children }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.currentUser);

  const axiosInstance = axios.create({
    baseURL: configData.SERVER
  });

  const reqInterceptor = (config) => {
    dispatch(allActions.notificationActions.clearError());
    dispatch(allActions.notificationActions.clearInfo());
    dispatch(allActions.notificationActions.clearWarning());
    dispatch(allActions.notificationActions.clearSuccess());
    if (user.user) {
      config.headers = {
        'Authorization': `Bearer ${user.user.Token}`
      };
    }
    return config;
  };
  const reqErrInterceptor = (error) => {
    console.log('errorInt');
    return Promise.reject(error);
  };
  const resInterceptor = (response) => {
    return response;
  };
  const resErrInterceptor = (error) => {
    try {
      if (error.response.status === 401) {
        if (error.response.data['error'] === 'Invalid Login') {
          dispatch(allActions.userActions.logOut());
          dispatch(allActions.notificationActions.setWarning({
            'title': 'Sessie verlopen',
            'message': 'Log opnieuw in'
          }));
        } else if (error.response.data['error'] === 'Invalid Token') {
          dispatch(allActions.notificationActions.setWarning({
            'title': 'Exact token verlopen',
            'message': 'Log opnieuw in bij exact'
          }));
          navigate('/exact/authentication');
        } else if (error.response.data['error'] === 'Invalid exact token') {
          dispatch(allActions.notificationActions.setWarning({
            'title': 'Exact token verlopen',
            'message': 'Log opnieuw in bij exact'
          }));
          navigate('/exact/authentication');
        } else if (error.response.data['error'] === 'Invalid Login Attempt') {
          dispatch(allActions.notificationActions.setWarning({
            'title': 'Ongeldige login',
            'message': 'Probeer opnieuw!'
          }));
        }
      } else if (error.response.status === 429) {
        dispatch(allActions.notificationActions.setError({
          'title': 'Exact overbelast',
          'message': 'Wacht een minuut en probeer het dan opnieuw!'
        }));
      } else if (error.response.status === 404) {
        dispatch(allActions.notificationActions.setError({
          'title': 'Not Found',
          'message': 'Data niet gevonden op de server!'
        }));
      } else if (error.response.status === 400) {
        dispatch(allActions.notificationActions.setError({
          'title': 'Interne fout!',
          'message': `Contact serverbeheerder! Error: ${error.response.data['error_description']}`
        }));
      } else if (error.response.status === 406) {
        dispatch(allActions.notificationActions.setError({
          'title': 'Foutmelding',
          'message': error.response.data['error_description']
        }));
      } else {
        dispatch(allActions.notificationActions.setError({
          'title': 'Onbekende fout!',
          'message': `Contact serverbeheerder! Error: ${error.response.status}`
        }));
      }
    } catch (error) {
      dispatch(allActions.notificationActions.setError({
        'title': 'Foutmelding',
        'message': 'Fout op de server graag melden aan beheerder!'
      }));
    }
    return Promise.reject(error);
  };

  axiosInstance.interceptors.request.use(reqInterceptor, reqErrInterceptor);
  axiosInstance.interceptors.response.use(resInterceptor, resErrInterceptor);

  return (<AxiosContext.Provider value={axiosInstance}>{children}</AxiosContext.Provider>);
};


const useAxios = () => {
  const axiosClient = useContext(AxiosContext);
  if (axiosClient === undefined) {
    throw new Error('useAxios must be used within a AxiosProvider');
  }
  return axiosClient;
};

export { AxiosProvider, useAxios };
