import React from 'react';
import { ArrowNarrowLeftIcon, ArrowNarrowRightIcon } from '@heroicons/react/solid';

const Pagination = ({ pages, currentPage, setCurrentPage }) => {
  const pagenumbers = [];

  const Number = ({ num }) => {
    return (
      <button
        onClick={() => setCurrentPage(num)}
        className={`
          border-transparent hover:text-gray-900 hover:border-gray-500
          border-t-2 pt-4 px-4 inline-flex items-center text-sm font-medium
          ${num === currentPage ? 'text-gray-600 border-gray-400' : 'text-gray-500'} `}>
        {num}
      </button>
    );
  };

  if (pages > 0) {
    pagenumbers.push(
      <Number key={1} num={1} />
    );
  }

  if (currentPage > 3) {
    pagenumbers.push(
      <span
        key={'dots1'}
        className="border-transparent text-gray-500 border-t-2 pt-4 px-4 inline-flex items-center text-sm font-medium">
        ...
      </span>
    );
  }

  if (currentPage > 2) {
    pagenumbers.push(
      <Number key={currentPage - 1} num={currentPage - 1} />
    );
  }

  if (currentPage > 1 && currentPage < pages) {
    pagenumbers.push(
      <Number key={currentPage} num={currentPage} />
    );
  }

  if ((currentPage + 1) < pages) {
    pagenumbers.push(
      <Number key={currentPage + 1} num={currentPage + 1} />
    );
  }

  if ((pages - currentPage) > 2) {
    pagenumbers.push(
      <span
        key={'dots2'}
        className="border-transparent text-gray-500 border-t-2 pt-4 px-4 inline-flex items-center text-sm font-medium">
        ...
      </span>
    );
  }


  if (pages > 1) {
    pagenumbers.push(
      <Number key={pages} num={pages} />
    );
  }

  return (
    <nav className="mb-4 sm:ml-4 sm:mr-4 border-t border-gray-200 max-w-7xl mx-auto px-4 mt-6 flex items-center justify-between sm:px-0">
      <div className="-mt-px w-0 flex-1 flex">
        {currentPage > 1 &&
          <button
            onClick={() => setCurrentPage(currentPage - 1)}
            className="
              border-t-2 border-transparent pt-4 pr-1 inline-flex items-center
              text-sm font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300">
            <ArrowNarrowLeftIcon className="mr-3 h-5 w-5 text-gray-400" aria-hidden="true" />
            Previous
          </button>
        }
      </div>
      <div
        key={'numbers'}
        className="hidden sm:-mt-px sm:flex">
        {pagenumbers}
      </div>
      <div>
        <button
        className='
          sm:hidden border-transparent hover:text-gray-900 hover:border-gray-500
          border-t-2 pt-4 px-4 inline-flex items-center text-sm font-medium text-gray-600 border-gray-400'>
        {currentPage}/{pages}
      </button>
      </div>
      <div className="-mt-px w-0 flex-1 flex justify-end">
        {currentPage !== pages && pages > 1 &&
          <button
            onClick={() => setCurrentPage(currentPage + 1)}
            className="
              border-t-2 border-transparent pt-4 pl-1 inline-flex items-center
              text-sm font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300">
            Next
            <ArrowNarrowRightIcon className="ml-3 h-5 w-5 text-gray-400" aria-hidden="true" />
          </button>
        }
      </div>
    </nav>
  );
};

export default Pagination;
