import React from 'react';
import { CalendarIcon, CubeIcon } from '@heroicons/react/solid';
import { useState } from 'react';
import { RowElement, Row, RowLine, RowTitle, List, ListContainer } from '../../widgets/ListComponents';
import Loading from '../../general/Loading';
import { useEffect } from 'react';
import { useAxios } from '../../../helpers/AxiosContext';


const ShopOrderReceiptList = () => {
  const [rawShopOrderReceipts, setRawShopOrderReceipts] = useState([]);
  const [shopOrderReceipts, setShopOrderReceipts] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const axiosClient = useAxios();

  useEffect(() => {
    axiosClient.get('api/shoporder/get_shoporder_receipts')
      .then((response) => {
        console.log(response.data);
        setRawShopOrderReceipts(response.data);
      })
      .catch((error) => {
        console.log(`Error ${error}`);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  if (isLoading) {
    return (
      <Loading></Loading>
    );
  }

  return (
    <ListContainer setSourceItems={setShopOrderReceipts} rawSourceItems={rawShopOrderReceipts}>
      <List>
        {shopOrderReceipts.map((shoporderreceipt) => (
          <Row
            key={shoporderreceipt.ID}
            link={`/shoporder/details/${shoporderreceipt.ShopOrderID}`}>
            <RowLine>
              <RowTitle>{shoporderreceipt.Item.Description}</RowTitle>
              <RowElement>Order: {shoporderreceipt.ShopOrderNumber}</RowElement>
            </RowLine>
            <RowLine>
              <RowElement>
                <CubeIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                Artikel: {shoporderreceipt.Item.Code}
              </RowElement>
              <RowElement>
                <CalendarIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                Verwerkingsdatum {shoporderreceipt.Created}
              </RowElement>
            </RowLine>
            <RowLine>
              <RowElement>
                Verwerkingsnummer: {shoporderreceipt.ReceiptNumber}
              </RowElement>
              <RowElement>
                Aantal: {shoporderreceipt.Quantity}
              </RowElement>
            </RowLine>
          </Row>
        ))}
      </List>
    </ListContainer>
  );
};

export default ShopOrderReceiptList;
