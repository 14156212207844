import React from 'react';
import { shopOrderMenu } from '../../../static-data/menus';
import Menu from '../../components/Menu';

const ShopOrderMenu = () => {
  return (
    <Menu menuOptions={shopOrderMenu}></Menu>
  );
};

export default ShopOrderMenu;
