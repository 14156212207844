import React from 'react';
import { salesOrderMenu } from '../../../static-data/menus';
import Menu from '../../components/Menu';


const SalesOrderMenu = () => {
  return (
    <Menu menuOptions={salesOrderMenu}></Menu>
  );
};

export default SalesOrderMenu;
