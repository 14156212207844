import React from 'react';
import { CalendarIcon, ClipboardCheckIcon } from '@heroicons/react/solid';
import { useState } from 'react';
import { StatusTextGray, StatusTextGreen, StatusTextOrange, StatusTextRed } from '../../widgets/StatusText';
import { RowElement, Row, RowLine, RowTitle, List, ListContainer } from '../../widgets/ListComponents';
import { useAxios } from '../../../helpers/AxiosContext';
import { useEffect } from 'react';
import Loading from '../../general/Loading';


const ShopOrderList = () => {
  const [rawShoporders, setRawShoporders] = useState([]);
  const [shoporders, setShoporders] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const axiosClient = useAxios();

  useEffect(() => {
    axiosClient.get('api/shoporder/get_shoporders')
      .then((response) => {
        setRawShoporders(response.data);
      })
      .catch((error) => {
        console.log(`Error ${error}`);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  if (isLoading) {
    return (
      <Loading></Loading>
    );
  }

  return (
    <ListContainer rawSourceItems={rawShoporders} setSourceItems={setShoporders}>
      <List>
        {shoporders.map((shoporder) => (
          <Row
            key={shoporder.ID}
            link={`/shoporder/details/${shoporder.ID}`}>
            <RowLine>
              <RowTitle>{shoporder.Description}</RowTitle>
              <RowElement>
                {
                  (shoporder.IsMonitored) ?
                    (shoporder.InStock) ?
                      <StatusTextGreen>Op voorraad</StatusTextGreen> :
                      (shoporder.PartiallyInStock) ?
                        <StatusTextOrange>Deels op voorraad</StatusTextOrange> :
                        <StatusTextRed>Niet op voorraad</StatusTextRed> :
                    <StatusTextGray>Niet berekend</StatusTextGray>
                }
              </RowElement>
            </RowLine>
            <RowLine>
              <RowElement>Order: {shoporder.ShopOrderNumber}</RowElement>
              <RowElement>
                <ClipboardCheckIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                {shoporder.ProducedQuantity}/{shoporder.PlannedQuantity}
              </RowElement>
            </RowLine>
            <RowLine>
              <RowElement>
                <CalendarIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                Startdatum {shoporder.PlannedStartDate}
              </RowElement>
              <RowElement>
                <CalendarIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                Einddatum {shoporder.PlannedDate}
              </RowElement>
            </RowLine>
          </Row>
        ))}
      </List>
    </ListContainer>
  );
};

export default ShopOrderList;
