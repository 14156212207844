import React, { Fragment, useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import allActions from '../../helpers/store/actions';
import logo from '../../images/logo-text.jpg';
import { mainNavigation, userNavigation } from '../../static-data/navigations';
import { Menu, Transition } from '@headlessui/react';
import { MenuIcon, XIcon, UserIcon } from '@heroicons/react/outline';


const Header = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.currentUser);
  const { pathname } = useLocation();

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const signOut = () => {
    dispatch(allActions.userActions.logOut());
    dispatch(allActions.notificationActions.setSuccess({
      'title': 'Success',
      'message': 'Successfully logged out!'
    }));
  };

  useEffect(() => {
    if (mobileMenuOpen) {
      setMobileMenuOpen(false);
    }
  }, [pathname]);


  return (
    <header className="shadow pt-2">
      <div className="max-w-7xl mx-auto px-2 sm:px-4 lg:divide-y lg:divide-gray-200 lg:px-8">
        <div className="relative h-16 flex justify-between">
          <div className="relative z-10 px-2 flex lg:px-0">
            <div className="flex-shrink-0 flex items-center">
              <Link
                className='rounded-md py-2 inline-flex items-center text-sm font-medium'
                to="/">
                <img
                  className="block h-12 w-auto"
                  src={logo}
                  alt="Cadeautje.me"
                />
              </Link>
              <nav className="hidden lg:py-2 lg:flex lg:space-x-2 ml-4" aria-label="Global">
                {mainNavigation.map((item) => (
                  <Link
                    key={item.name}
                    to={item.link}
                    className={`
                        hover:bg-gray-100 rounded-md
                        py-2 px-3 items-center text-sm font-medium text-blue
                        ${pathname === item.link ? 'bg-gray-200' : ''}`}>
                    {item.name}
                  </Link>
                ))}
              </nav>
            </div>
          </div>
          <div className="relative z-10 flex items-center lg:hidden">
            {mobileMenuOpen ? (
              <button
                onClick={() => setMobileMenuOpen(false)}
                className="
                rounded-md p-2 inline-flex items-center justify-center text-blue hover:bg-gray-100">
                <span className="sr-only">Open menu</span>
                <XIcon className="block h-6 w-6" aria-hidden="true" />
              </button>
            ) : (
              <button
                onClick={() => setMobileMenuOpen(true)}
                className="
                  rounded-md p-2 inline-flex items-center justify-center text-blue hover:bg-gray-100">
                <span className="sr-only">Open menu</span>
                <MenuIcon className="block h-6 w-6" aria-hidden="true" />
              </button>
            )}
          </div>
          <div className="hidden lg:relative lg:z-20 lg:ml-4 lg:flex lg:items-center">
            <Menu as="div" className="flex-shrink-0 relative ml-4">
              <div>
                <Menu.Button
                  className="flex focus:outline-none">
                  <span className="sr-only">Open user menu</span>
                  <UserIcon className="block mt-2 h-6 w-6 bg-white text-blue" aria-hidden="true" />
                </Menu.Button>
              </div>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95">
                <Menu.Items
                  className="
                  origin-top-right absolute right-0 mt-2 w-40 rounded-md shadow-lg
                  bg-white ring-1 ring-black ring-opacity-5 py-1 focus:outline-none">
                  {userNavigation.map((item) => (
                    <Menu.Item
                      key={item.name}
                      as="p">
                      <Link
                        key={item.name}
                        to={item.link}
                        as="a"
                        className='w-full hover:bg-gray-100 block py-2 px-4 text-sm text-left text-gray-500 cursor-pointer'>
                        {item.name}
                      </Link>
                    </Menu.Item>
                  ))}
                  <Menu.Item>
                    <button
                      onClick={() => signOut()}
                      className='w-full hover:bg-gray-100 block py-2 px-4 text-sm text-left text-gray-500 cursor-pointer'>
                      Uitloggen
                    </button>
                  </Menu.Item>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
        </div>
        <Transition
          show={mobileMenuOpen}>
          <div className="pt-2 pb-3 px-2 space-y-1">
            {mainNavigation.map((item) => (
              <p
                key={item.name}
                as="p">
                <Link
                  key={item.name}
                  to={item.link}
                  as="a"
                  className={`
              block rounded-md py-2 px-3 text-base font-medium
              text-blue hover:bg-gray-50 hover:text-black
              ${pathname === item.link ? 'underline' : ''}`}>
                  {item.name}
                </Link>
              </p>
            ))}
          </div>
          <div className="border-t border-blue-400 pt-4 pb-3">
            <div className="px-4 flex items-center">
              <div className="flex-shrink-0">
                <UserIcon className="block h-6 w-6 bg-white text-blue" aria-hidden="true" /></div>
              <div className="ml-3">
                <div className="text-base font-medium text-blue">{user.user.first_name} {user.user.last_name}</div>
              </div>
            </div>
            <div className="mt-3 px-2 space-y-1">
              {userNavigation.map((item) => (
                <p
                  key={item.name}>
                  <Link
                    key={item.name}
                    to={item.link}
                    as="a"
                    className='block rounded-md py-2 px-3 text-base font-medium text-blue hover:bg-gray-50 hover:text-gray-900'>
                    {item.name}
                  </Link>
                </p>
              ))}
              <p
                onClick={() => signOut()}
                className="block rounded-md py-2 px-3 text-base font-medium text-blue hover:bg-gray-50 hover:text-gray-900">
                Uitloggen
              </p>
            </div>
          </div>
        </Transition>
      </div>
    </header>
  );
};

export default Header;
