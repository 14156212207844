import { CalendarIcon } from '@heroicons/react/solid';
import React, { useEffect, useState } from 'react';
import { useAxios } from '../../../helpers/AxiosContext';
import Loading from '../../general/Loading';
import { List, ListContainer, Row, RowElement, RowLine, RowTitle } from '../../widgets/ListComponents';
import { StatusTextBlack, StatusTextGray, StatusTextGreen, StatusTextOrange, StatusTextRed } from '../../widgets/StatusText';


const SalesOrderList = () => {
  const [isLoading, setLoading] = useState(true);
  const [rawSalesOrders, setRawSalesOrders] = useState([]);
  const [salesOrders, setSalesOrders] = useState([]);
  const axiosClient = useAxios();

  useEffect(() => {
    axiosClient.get('api/salesorder/get_salesorders')
      .then((response) => {
        console.log(response.data);
        setRawSalesOrders(response.data);
      })
      .catch((error) => {
        console.log(`Error ${error}`);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  if (isLoading) {
    return (
      <Loading></Loading>
    );
  }

  return (
    <ListContainer rawSourceItems={rawSalesOrders} setSourceItems={setSalesOrders}>
      <List>
        {salesOrders.map((salesOrder) => (
          <Row
            key={salesOrder.ID}
            link={`/salesorder/details/${salesOrder.ID}`}>
            <RowLine>
              <RowTitle>{salesOrder.DeliverToName}</RowTitle>
              <RowElement>
                {
                  (salesOrder.DeliveryStatus === 12) ?
                    <StatusTextGreen>Open</StatusTextGreen> :
                    (salesOrder.DeliveryStatus === 20) ?
                      <StatusTextOrange>Deels geleverd</StatusTextOrange> :
                      (salesOrder.DeliveryStatus === 21) ?
                        <StatusTextBlack>Afgerond</StatusTextBlack> :
                        (salesOrder.DeliveryStatus === 45) ?
                          <StatusTextRed>Geannuleerd</StatusTextRed> :
                          <StatusTextGray>Status Onbekend</StatusTextGray>
                }
              </RowElement>
            </RowLine>
            <RowLine>
              <RowElement>{salesOrder.Description}</RowElement>
              <RowElement>Order: {salesOrder.OrderNumber}</RowElement>
            </RowLine>
            <RowLine>
              <RowElement>
                <CalendarIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                Besteldatum {salesOrder.OrderDate}
              </RowElement>
              <RowElement>
                <CalendarIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                Leveringsdatum {salesOrder.DeliveryDate}
              </RowElement>
            </RowLine>
          </Row>
        ))}
      </List>
    </ListContainer>
  );
};

export default SalesOrderList;
