import { CalendarIcon, DocumentTextIcon } from '@heroicons/react/solid';
import React, { useEffect, useState } from 'react';
import { useAxios } from '../../../helpers/AxiosContext';
import Loading from '../../general/Loading';
import { List, ListContainer, Row, RowElement, RowLine, RowTitle } from '../../widgets/ListComponents';


const GoodsReceiptList = () => {
  const [isLoading, setLoading] = useState(true);
  const [rawGoodsReceipts, setRawGoodsReceipts] = useState([]);
  const [goodsReceipts, setGoodsReceipts] = useState([]);
  const axiosClient = useAxios();

  useEffect(() => {
    axiosClient.get('api/purchaseorder/get_goodsreceipts')
      .then((response) => {
        setRawGoodsReceipts(response.data);
        console.log(response.data);
      })
      .catch((error) => {
        console.log(`Error ${error}`);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  if (isLoading) {
    return (
      <Loading></Loading>
    );
  }

  return (
    <ListContainer rawSourceItems={rawGoodsReceipts} setSourceItems={setGoodsReceipts}>
      <List>
        {goodsReceipts.map((goodsreceipt) => (
          <Row
            key={goodsreceipt.ID}
            link={`/goodsreceipt/details/${goodsreceipt.ID}`}>
            <RowLine>
              <RowTitle>{goodsreceipt.SupplierName}</RowTitle>
              <RowElement>
                <CalendarIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                Ontvangstdatum {goodsreceipt.ReceiptDate}
              </RowElement>
            </RowLine>
            <RowLine>
              <RowElement>Order: {goodsreceipt.ReceiptNumber}</RowElement>
              <RowElement>
                <DocumentTextIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                Beschrijving:
                {goodsreceipt.Description ?
                  <span>&nbsp; {goodsreceipt.Description}</span> :
                  <span>&nbsp; None</span>}
              </RowElement>
            </RowLine>
          </Row>
        ))}
      </List>
    </ListContainer>
  );
};

export default GoodsReceiptList;
