import { CollectionIcon, ClipboardListIcon, TruckIcon, CalculatorIcon, OfficeBuildingIcon } from '@heroicons/react/outline';


const shopOrderMenu = [
  {
    title: 'Assemblage Overzicht',
    description: 'Overzicht van alle open assemblages',
    icon: ClipboardListIcon,
    link: '/shoporder/list',
    actieTitle: 'Bekijk overzicht'
  },
  {
    title: 'Verwerkte Assemblages',
    description: 'Overzicht van alle verwerkte (deel)assemblages',
    icon: CollectionIcon,
    link: '/shoporderreceipt/list',
    actieTitle: 'Bekijk overzicht'
  }
];


const purchaseOrderMenu = [
  {
    title: 'Inkomende Transport',
    description: 'Overzicht van alle inkomende goederen',
    icon: ClipboardListIcon,
    link: '/purchaseorder/list',
    actieTitle: 'Bekijk overzicht'
  },
  {
    title: 'Aangenomen (Deel)Transport',
    description: 'Overzicht van alle aangenomen goederen',
    icon: TruckIcon,
    link: '/goodsreceipt/list',
    actieTitle: 'Bekijk overzicht'
  }
];


const salesOrderMenu = [
  {
    title: 'Open Bestellingen',
    description: 'Overzicht van alle open bestellingen',
    icon: ClipboardListIcon,
    link: '/salesorder/list',
    actieTitle: 'Bekijk overzicht'
  },
  {
    title: 'Verzonden Goederen',
    description: 'Overzicht van alle verwerkte (deel)bestellingen',
    icon: TruckIcon,
    link: '/goodsdelivery/list',
    actieTitle: 'Bekijk overzicht'
  }
];


const storageMenu = [
  {
    title: 'Opslag Overzicht',
    description: 'Overzicht van opslag en locaties',
    icon: OfficeBuildingIcon,
    link: '/storage/list',
    actieTitle: 'Bekijk overzicht'
  },
  {
    title: 'Goederen Overzicht',
    description: 'Overzicht van alle producten in het systeem',
    icon: CollectionIcon,
    link: '/item/list',
    actieTitle: 'Bekijk overzicht'
  },
  {
    title: 'Overzicht Jaartelling',
    description: 'Overzicht voor alles met betrekking tot de jaartelling',
    icon: CalculatorIcon,
    link: '/opslag/jaartelling',
    actieTitle: 'Bekijk overzicht'
  }
];


export { shopOrderMenu, purchaseOrderMenu, salesOrderMenu, storageMenu };
