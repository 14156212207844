import {
  CalendarIcon, LinkIcon, PrinterIcon
} from '@heroicons/react/solid';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAxios } from '../../../helpers/AxiosContext';
import Loading from '../../general/Loading';
import { DetailsActions, DetailsBox, DetailsElement, DetailsList, DetailsRow, DetailsSubtitle, DetailsTitle } from '../../widgets/DetailComponents';
import { LinkButton } from '../../widgets/FancyButtons';
import { List, ListContainer, Row, RowElement, RowLine, RowTitle } from '../../widgets/ListComponents';


const GoodsReceiptLineDetails = () => {
  const { id } = useParams();
  const [isLoading, setLoading] = useState(true);
  const [goodsReceiptLine, setGoodsReceiptLine] = useState({});
  const [batchNumbers, setBatchNumbers] = useState([]);
  const [serialNumbers, setSerialNumbers] = useState([]);
  const axiosClient = useAxios();

  const printSerialNumber = (serieBatchNumbers, name) => {
    setLoading(true);
    axiosClient.post('api/printing/serie_batch', {
      'serie_batch_numbers': serieBatchNumbers,
      'name': name
    })
      .then((response) => {
        console.log(response.data);
      })
      .catch((error) => {
        console.log(`Error ${error}`);
      }).finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    axiosClient.get(`api/purchaseorder/get_goodsreceiptline/${id}`)
      .then((response) => {
        console.log(response.data);
        setGoodsReceiptLine(response.data);
      })
      .catch((error) => {
        console.log(`Error ${error}`);
      }).finally(() => {
        setLoading(false);
      });
  }, [id]);

  if (isLoading) {
    return (
      <Loading></Loading>
    );
  }

  return (
    <div className="min-h-full">
      <DetailsBox>
        <DetailsList>
          <DetailsSubtitle>Lijnnummer: {goodsReceiptLine.LineNumber}</DetailsSubtitle>
          <DetailsTitle>Artikel: {goodsReceiptLine.Item.Description}</DetailsTitle>
          <DetailsSubtitle>{goodsReceiptLine.Description}</DetailsSubtitle>
          <DetailsRow>
            <DetailsElement> PurchaseOrderNumber: {goodsReceiptLine.PurchaseOrderNumber}</DetailsElement>
            <DetailsElement>
              <CalendarIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
              Ontvangstdatum {goodsReceiptLine.Created}
            </DetailsElement>
          </DetailsRow>
        </DetailsList>
        <DetailsActions>
          <LinkButton url={`/goodsreceipt/details/${goodsReceiptLine.GoodsReceiptID}`}>
            <LinkIcon className="-ml-1 mr-2 h-5 w-5 text-gray-400" aria-hidden="true" />
            Bekijk volledige ontvangsbon
          </LinkButton>
        </DetailsActions>
        <DetailsActions>
          <LinkButton url={`/purchaseorder/details/${goodsReceiptLine.PurchaseOrderID}`}>
            <LinkIcon className="-ml-1 mr-2 h-5 w-5 text-gray-400" aria-hidden="true" />
            Bekijk orginele bestelling
          </LinkButton>
        </DetailsActions>
      </DetailsBox>
      {goodsReceiptLine.Item.IsBatchItem &&
        <ListContainer setSourceItems={setBatchNumbers} rawSourceItems={goodsReceiptLine.BatchNumbers} title='Batchnumbers' pageSize={20}>
          <button
            type="button"
            onClick={() => {
              const mapSBatchNumber = (BatchNumber) => {
                return BatchNumber.BatchNumber;
              };
              const mappedBatchNumbers = goodsReceiptLine.BatchNumbers.map(mapSBatchNumber);
              printSerialNumber(mappedBatchNumbers, goodsReceiptLine.Item.Description);
            }}
            className="
                          inline-flex items-center px-4 py-1 h-10 border border-gray-300 rounded-md
                          shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50
                          focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-purple-500"
          >
            <PrinterIcon className="-ml-1 mr-2 h-5 w-5 text-gray-400" aria-hidden="true" />
            Print alle labels
          </button>
          <List>
            {batchNumbers.map((batchNumber) => (
              <Row
                key={batchNumber.BatchNumber}>
                <RowLine>
                  <RowTitle>{batchNumber.BatchNumber}</RowTitle>
                  <RowElement>Aangenomen hoeveelheid: {batchNumber.Quantity}</RowElement>
                </RowLine>
                <RowLine>
                  <button
                    type="button"
                    onClick={() => {
                      printSerialNumber([batchNumber.BatchNumber], goodsReceiptLine.Item.Description);
                    }}
                    className="
                          inline-flex items-center px-4 py-1 h-10 border border-gray-300 rounded-md
                          shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50
                          focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-purple-500"
                  >
                    <PrinterIcon className="-ml-1 mr-2 h-5 w-5 text-gray-400" aria-hidden="true" />
                    Print Label
                  </button>
                </RowLine>
              </Row>
            ))}
          </List>
        </ListContainer>
      }
      {goodsReceiptLine.Item.IsSerialItem &&
        <ListContainer setSourceItems={setSerialNumbers} rawSourceItems={goodsReceiptLine.SerialNumbers} title='SerialNumbers' pageSize={20}>
          <button
            type="button"
            onClick={() => {
              const mapSerialNumber = (SerialNumber) => {
                return SerialNumber.SerialNumber;
              };
              const mappedSerialNumbers = goodsReceiptLine.SerialNumbers.map(mapSerialNumber);
              printSerialNumber(mappedSerialNumbers, goodsReceiptLine.Item.Description);
            }}
            className="
                          inline-flex items-center px-4 py-1 h-10 border border-gray-300 rounded-md
                          shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50
                          focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-purple-500"
          >
            <PrinterIcon className="-ml-1 mr-2 h-5 w-5 text-gray-400" aria-hidden="true" />
            Print alle labels
          </button>
          <List>
            {serialNumbers.map((serialNumber, index) => (
              <Row
                key={serialNumber.SerialNumber}>
                <RowLine>
                  <RowTitle>{serialNumber.SerialNumber}</RowTitle>
                  <button
                    type="button"
                    onClick={() => {
                      printSerialNumber([serialNumber.SerialNumber], goodsReceiptLine.Item.Description);
                    }}
                    className="
                          inline-flex items-center px-4 py-1 h-10 border border-gray-300 rounded-md
                          shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50
                          focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-purple-500"
                  >
                    <PrinterIcon className="-ml-1 mr-2 h-5 w-5 text-gray-400" aria-hidden="true" />
                    Print Label
                  </button>
                </RowLine>
              </Row>
            ))}
          </List>
        </ListContainer>
      }
    </div>
  );
};

export default GoodsReceiptLineDetails;
