import React from 'react';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { useState } from 'react';
import Loading from '../../general/Loading';
import { useAxios } from '../../../helpers/AxiosContext';
import { CalendarIcon, PlusCircleIcon, TrashIcon } from '@heroicons/react/solid';
import BarcodePopup from '../../components/BarcodePopup';
import { DetailsActions, DetailsBox, DetailsList, DetailsSubtitle, DetailsTitle } from '../../widgets/DetailComponents';
import { ActionButton } from '../../widgets/FancyButtons';
import { List, ListContainer, Row, RowElement, RowLine, RowTitle } from '../../widgets/ListComponents';
import { QrcodeIcon } from '@heroicons/react/outline';
import ConfirmPopup from '../../widgets/ConfirmPopup';

const LocationDetails = () => {
  const { id } = useParams();
  const [isLoading, setLoading] = useState(true);
  const [location, setLocation] = useState({});
  const [exactObjects, setExactObjects] = useState([]);
  const axiosClient = useAxios();
  const [addProductPopopOpen, setAddProductPopopOpen] = useState(false);
  const [barcode, setBarcode] = useState('');
  const [exactObjectID, setExactObjectID] = useState('');
  const [openConfirm, setOpenConfirm] = useState(false);

  const getLocation = () => {
    setLoading(true);
    axiosClient.get(`api/location/get_location/${id}`)
      .then((response) => {
        console.log(response.data);
        setLocation(response.data);
      })
      .catch((error) => {
        console.log(`Error ${error}`);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getLocation();
  }, [id]);

  const addProduct = () => {
    axiosClient.post('api/location/add_product', { 'location_id': id, 'barcode': barcode })
      .then((response) => {
        console.log(response.data);
        getLocation();
      })
      .catch((error) => {
        console.log(`Error ${error}`);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const removeProduct = () => {
    setLoading(true);
    axiosClient.delete(`api/location/remove_product/${id}/${exactObjectID}`)
      .then((response) => {
        console.log(response.data);
        getLocation();
      })
      .catch((error) => {
        console.log(`Error ${error}`);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const openAddProductPopup = () => {
    setAddProductPopopOpen(true);
  };

  if (isLoading) {
    return (
      <Loading></Loading>
    );
  }

  return (
    <div className="min-h-full">
      <DetailsBox>
        <BarcodePopup open={addProductPopopOpen} setOpen={setAddProductPopopOpen} barcode={barcode} setBarcode={setBarcode} submitForm={addProduct} label="Plaats product op locatie"></BarcodePopup>
        <DetailsList>
          <DetailsSubtitle>Locatie:   {location.Description}</DetailsSubtitle>
          <DetailsTitle>{location.Barcode}</DetailsTitle>
          <DetailsSubtitle>Opslagnummer: {location.StorageNumber}</DetailsSubtitle>
        </DetailsList>
        <DetailsActions>
          <ActionButton
            action={openAddProductPopup}>
            <PlusCircleIcon className="-ml-1 mr-2 h-5 w-5 text-gray-400" aria-hidden="true" />
            Plaats Product
          </ActionButton>
        </DetailsActions>
      </DetailsBox>
      <ListContainer setSourceItems={setExactObjects} rawSourceItems={location.ExactObjects} title='Items' pageSize={5}>
        <ConfirmPopup setConfirm={removeProduct} setOpenConfirm={setOpenConfirm} openConfirm={openConfirm}></ConfirmPopup>
        <List>
          {exactObjects.map((exactObject) => (
            (exactObject.ExactType === 'ITM') ?
              <Row
                key={exactObject.ID}
                link={`/item/details/${exactObject.Item.ID}`}>
                <RowLine>
                  <RowTitle>{exactObject.Item.Description}</RowTitle>
                  <RowElement>Type tracking: None</RowElement>
                </RowLine>
                <RowLine>
                  <RowElement>
                    <QrcodeIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                    {exactObject.Item.Barcode}
                  </RowElement>
                  <RowElement>
                    <button
                      onClick={(event) => {
                        event.preventDefault();
                        setExactObjectID(exactObject.ID);
                        setOpenConfirm(true);
                      }}
                      className="flex">
                      <TrashIcon className="-ml-1 mr-2 h-10 w-10 text-gray-400 hover:text-black" aria-hidden="true" />
                    </button>
                  </RowElement>
                </RowLine>
              </Row> :
              (exactObject.ExactType === 'GRL') ?
                <Row
                  key={exactObject.ID}
                  link={`/item/details/${exactObject.GoodsReceiptLine.Item.ID}`}>
                  <RowLine>
                    <RowTitle>{exactObject.GoodsReceiptLine.Item.Description}</RowTitle>
                    <RowElement>Type tracking: Levering</RowElement>
                  </RowLine>
                  <RowLine>
                    <RowElement>
                      <QrcodeIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                      {exactObject.GoodsReceiptLine.Item.Barcode}
                    </RowElement>
                    <RowElement>
                      <CalendarIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                      Leveringsdatum {exactObject.GoodsReceiptLine.Created}
                    </RowElement>
                  </RowLine>
                  <RowLine>
                    <RowElement>
                      <button
                        onClick={(event) => {
                          event.preventDefault();
                          setExactObjectID(exactObject.ID);
                          setOpenConfirm(true);
                        }}
                        className="flex">
                        <TrashIcon className="-ml-1 mr-2 h-10 w-10 text-gray-400 hover:text-black" aria-hidden="true" />
                      </button>
                    </RowElement>
                  </RowLine>
                </Row> :
                (exactObject.ExactType === 'SOR') ?
                  <Row
                    key={exactObject.ID}
                    link={`/item/details/${exactObject.Item.ID}`}>
                    <RowLine>
                      <RowTitle>{exactObject.ShopOrderReceipt.Item.Description}</RowTitle>
                      <RowElement>
                        <QrcodeIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                        {exactObject.ShopOrderReceipt.Item.Barcode}
                      </RowElement>
                    </RowLine>
                    <RowLine>
                      <RowElement>Type tracking: Assemblage</RowElement>
                      <RowElement>
                        <CalendarIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                        Assemblagedatum {exactObject.ShopOrderReceipt.Created}
                      </RowElement>
                    </RowLine>
                    <RowLine>
                      <RowElement>
                        <button
                          onClick={(event) => {
                            event.preventDefault();
                            setExactObjectID(exactObject.ID);
                            setOpenConfirm(true);
                          }}
                          className="flex">
                          <TrashIcon className="-ml-1 mr-2 h-10 w-10 text-gray-400 hover:text-black" aria-hidden="true" />
                        </button>
                      </RowElement>
                    </RowLine>
                  </Row> :
                  (exactObject.ExactType === 'GDL') ?
                    <Row
                      key={exactObject.ID}
                      link={`/item/details/${exactObject.Item.ID}`}>
                      <RowLine>
                        <RowTitle>{exactObject.GoodsDeliveryLine.Item.Description}</RowTitle>
                        <RowElement>
                          <QrcodeIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                          {exactObject.GoodsDeliveryLine.Item.Barcode}
                        </RowElement>
                      </RowLine>
                      <RowLine>
                        <RowElement>Type tracking: Delivery</RowElement>
                        <RowElement>
                          <CalendarIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                          Leveringsdatum {exactObject.GoodsDeliveryLine.DeliveryDate}
                        </RowElement>
                      </RowLine>
                      <RowLine>
                        <RowElement>
                          <button
                            onClick={(event) => {
                              event.preventDefault();
                              setExactObjectID(exactObject.ID);
                              setOpenConfirm(true);
                            }}
                            className="flex">
                            <TrashIcon className="-ml-1 mr-2 h-10 w-10 text-gray-400 hover:text-black" aria-hidden="true" />
                          </button>
                        </RowElement>
                      </RowLine>
                    </Row> :
                    <div>Interne fout. Graag melden aan beheerder!</div>
          ))}
        </List>
      </ListContainer>
    </div >
  );
};

export default LocationDetails;
