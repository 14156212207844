import React, { Fragment, useEffect } from 'react';
import { CubeIcon, CollectionIcon, XIcon, ChevronDownIcon } from '@heroicons/react/solid';
import { useState } from 'react';
import Loading from '../../general/Loading';
import { useAxios } from '../../../helpers/AxiosContext';
import { Dialog, Transition } from '@headlessui/react';
import { List, ListContainer, Row, RowElement, RowLine, RowTitle } from '../../widgets/ListComponents';
import { ActionButton } from '../../widgets/FancyButtons';


const ItemList = () => {
  const [isLoading, setLoading] = useState(true);
  const [items, setItems] = useState([]);
  const [rawItems, setRawItems] = useState([]);
  const [pageItems, setPageItems] = useState([]);

  // Filters
  const [descriptionFilter, setDescriptionFilter] = useState('');
  const [bardcodeFilter, setBardcodeFilter] = useState('');
  const [itemCodeFilter, setItemCodeFilter] = useState('');

  const [showSort, setShowSort] = useState(false);
  const axiosClient = useAxios();

  useEffect(() => {
    axiosClient.get('api/item/get_items')
      .then((response) => {
        console.log(response.data);
        setItems(response.data);
        setRawItems(response.data);
      })
      .catch((error) => {
        console.log(`Error ${error}`);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  if (isLoading) {
    return (
      <Loading></Loading>
    );
  }

  const filter = () => {
    setShowSort(false);
    setLoading(true);
    setItems([]);
    const filtered = [];
    rawItems.forEach((currentItem) => {
      let allowed = true;
      if (descriptionFilter !== '') {
        if (!currentItem.Description.toLowerCase().includes(descriptionFilter.toLowerCase())) {
          allowed = false;
        };
      };
      if (bardcodeFilter !== '') {
        if (!currentItem.Description.toLowerCase().includes(bardcodeFilter.toLowerCase())) {
          allowed = false;
        };
      };
      if (itemCodeFilter !== '') {
        if (!currentItem.ShopOrderNumber.toString().includes(itemCodeFilter.toLowerCase())) {
          allowed = false;
        };
      };
      if (allowed) {
        filtered.push(currentItem);
      };
      setItems(filtered);
    });
    setLoading(false);
  };

  const openFilter = () => {
    setShowSort(true);
  };

  const clearFilter = () => {
    setDescriptionFilter('');
    setBardcodeFilter('');
    setItemCodeFilter('');
    filter();
  };

  return (
    <ListContainer setSourceItems={setPageItems} rawSourceItems={items} title='Assemblage bonnen' pageSize={5} >
      <Transition.Root show={showSort} as={Fragment}>
        <Dialog as="div" className="relative z-20" onClose={setShowSort}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <div className="fixed z-20 inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-full text-center p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                <Dialog.Panel
                  className="
                        relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden
                        shadow-xl transform transition-all sm:my-8 sm:max-w-xl sm:w-full sm:p-6">
                  <div className="bg-blue-700 py-6 px-4 sm:px-6">
                    <div className="flex items-center justify-between">
                      <Dialog.Title className="text-lg font-medium text-white">Filter Assemblages</Dialog.Title>
                      <div className="ml-3 flex h-7 items-center">
                        <button
                          type="button"
                          className="
                                rounded-md bg-blue-700 text-blue-200 hover:text-white
                                focus:outline-none focus:ring-2 focus:ring-white"
                          onClick={() => setShowSort(false)}>
                          <span className="sr-only">Sluit</span>
                          <XIcon className="h-6 w-6" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-6">
                      <div>
                        <label htmlFor="item" className="block text-sm font-medium text-gray-700">
                          Description
                        </label>
                        <div className="mt-1">
                          <input
                            id="item"
                            name="item"
                            type="text"
                            value={descriptionFilter}
                            onChange={(e) => setDescriptionFilter(e.target.value)}
                            className="
                                  appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md
                                  shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"/>
                        </div>
                        <label htmlFor="item" className="block text-sm font-medium text-gray-700">
                          Barcode
                        </label>
                        <div className="mt-1">
                          <input
                            id="item"
                            name="item"
                            type="text"
                            value={bardcodeFilter}
                            onChange={(e) => setBardcodeFilter(e.target.value)}
                            className="
                                  appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md
                                  shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"/>
                        </div>
                        <label htmlFor="item" className="block text-sm font-medium text-gray-700">
                          Item Code
                        </label>
                        <div className="mt-1">
                          <input
                            id="item"
                            name="item"
                            type="text"
                            value={itemCodeFilter}
                            onChange={(e) => setItemCodeFilter(e.target.value)}
                            className="
                                  appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md
                                  shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"/>
                        </div>
                      </div>
                      <div>
                        <button
                          type="submit"
                          onClick={filter}
                          className="
                                w-full flex justify-center py-2 px-4 border border-transparent rounded-md
                                shadow-sm text-sm font-medium text-white bg-blue hover:bg-blue-400 focus:outline-none
                                focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                          Filter
                        </button>
                      </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <ActionButton action={openFilter}>
        Filter
        <ChevronDownIcon className="flex-shrink-0 -mr-1 ml-1 h-5 w-5 text-gray-300 group-hover:text-gray-100" aria-hidden="true" />
      </ActionButton>
      <ActionButton action={clearFilter}>
        Clear Filter
        <XIcon className="flex-shrink-0 -mr-1 ml-1 h-5 w-5 text-gray-300 group-hover:text-gray-100" aria-hidden="true" />
      </ActionButton>
      <List>
        {pageItems.map((pageItem) => (
          <Row
            key={pageItem.ID}
            link={`/item/details/${pageItem._id}`}>
            <RowLine>
              <RowTitle>{pageItem.Description}</RowTitle>
              <RowElement>
                <CubeIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                Artikel: {pageItem.Code}
              </RowElement>
            </RowLine>
            <RowLine>
              <RowElement>Barcode: {pageItem.Barcode}</RowElement>
              <RowElement>
                {(pageItem.Class_01) ?
                  <span>Category: {pageItem['Class_01']['Description']}</span> :
                  <span></span>}
              </RowElement>
            </RowLine>
            <RowLine>
              <RowElement>
                Voorraad: {pageItem.Stock} ({pageItem.UnitDescription})
              </RowElement>
              <RowElement>
                {(pageItem.Class_02) ?
                  <span>Sub-Category: {pageItem['Class_02']['Description']}</span> :
                  <span></span>}
              </RowElement>
            </RowLine>
            <RowLine>
              <RowElement>
                <CollectionIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                Item group: {pageItem.ItemGroupDescription}
              </RowElement>
              <RowElement>
                {(pageItem.Class_03) ?
                  <span>Sub-Sub-Category: {pageItem['Class_03']['Description']}</span> :
                  <span></span>}
              </RowElement>
            </RowLine>
            <RowLine>
              <RowElement>
                {(pageItem.Class_05) ?
                  <span className="flex">
                    <CollectionIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                    Manufacturing Category: {pageItem['Class_05']['Description']}
                  </span> :
                  <span></span>}
              </RowElement>
            </RowLine>
          </Row>
        ))}
      </List>
    </ListContainer>
  );
};

export default ItemList;
