import {
  CalculatorIcon, CalendarIcon, ClipboardCheckIcon, CubeIcon, PrinterIcon
} from '@heroicons/react/solid';
import { saveAs } from 'file-saver';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAxios } from '../../../helpers/AxiosContext';
import ConfirmNumberPopup from '../../components/ConfirmNumberPopup';
import CreateShopReceiptPopup from '../../components/CreateShopReceiptPopup';
import CreateShopReceiptPopupBatch from '../../components/CreateShopReceiptPopupBatch';
import Loading from '../../general/Loading';
import { DetailsActions, DetailsBox, DetailsElement, DetailsList, DetailsRow, DetailsSubtitle, DetailsTitle } from '../../widgets/DetailComponents';
import { ActionButton } from '../../widgets/FancyButtons';
import { List, ListContainer, Row, RowElement, RowLine, RowTitle } from '../../widgets/ListComponents';
import { StatusTextBlack, StatusTextGray, StatusTextGreen, StatusTextOrange, StatusTextRed } from '../../widgets/StatusText';


const ShopOrderDetails = () => {
  const { id } = useParams();
  const [isLoading, setLoading] = useState(true);
  const [shoporder, setShoporder] = useState({});
  const [shoporderReceipts, setShoporderReceipts] = useState([]);
  const [materialPlanDetails, setMaterialPlanDetails] = useState([]);
  const [produceNumber, setProduceNumber] = useState(1);
  const [produceOpen, setProduceOpen] = useState(false);
  const axiosClient = useAxios();
  const [receiptData, setReceiptData] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    axiosClient.get(`api/shoporder/get_shoporder/${id}`)
      .then((response) => {
        console.log(response.data);
        setShoporder(response.data);
      })
      .catch((error) => {
        console.log(`Error ${error}`);
      }).finally(() => {
        setLoading(false);
      });
  }, [id]);


  const printStuklijst = () => {
    setLoading(true);
    axiosClient.get(`api/printing/billofmaterials/${id}`, { responseType: 'blob' })
      .then((response) => {
        console.log(response.data);
        const file = new Blob([response.data], { type: 'application/pdf' });
        saveAs(file, `billofmaterials-${id}.pdf`);
      })
      .catch((error) => {
        console.log(`Error ${error}`);
      }).finally(() => {
        setLoading(false);
      });
  };

  const openProduceProducts = () => {
    const data = shoporder.MaterialPlanDetails.map((x) => {
      if (x.IsSerial) {
        x['SerialNumbers'] = [];
        for (let i = 0; i < x.PlannedQuantityFactor; i++) {
            x['SerialNumbers'].push('');
        };
      }
      if (x.IsBatch) {
        if (shoporder.IsBatch) {
            x['BatchNumber'] = '';
            x['Quantity'] = 1;
        } else {
            x['BatchNumbers'] = [];
        }
      }
      return x;
    });
    setReceiptData(data);
    setProduceOpen(true);
  };

  const produceProducts = () => {
    setLoading(true);
    if (shoporder.IsSerial) {
      console.log(receiptData);
      const formattedMaterials = [];
      receiptData.forEach((line) => {
        const materialLine = {
          'IsBatch': line.IsBatch,
          'IsSerial': line.IsSerial,
          'ShopOrderMaterialPlanID': line.ID,
          'Item': line.Item.ID,
          'ItemDescription': line.Item.Description
        };
        if (line.IsBatch) {
          materialLine['BatchNumbers'] = line.BatchNumbers;
        }
        if (line.IsSerial) {
          materialLine['SerialNumbers'] = line.SerialNumbers;
        }
        formattedMaterials.push(materialLine);
      });
      console.log(formattedMaterials);
      axiosClient.post(`api/shoporder/process_shoporder/${id}`, { 'Materials': formattedMaterials })
        .then((response) => {
          console.log(response.data);
          navigate(`/shoporderreceipt/details/${response.data['d']['StockTransactionId']}`);
        })
        .catch((error) => {
          console.log(`Error ${error}`);
        }).finally(() => {
          setLoading(false);
        });
    } else if (shoporder.IsBatch) {
        console.log(receiptData);
        const formattedMaterials = [];
        receiptData.forEach((line) => {
          const materialLine = {
            'IsBatch': line.IsBatch,
            'IsSerial': line.IsSerial,
            'ShopOrderMaterialPlanID': line.ID,
            'Item': line.Item.ID,
            'ItemDescription': line.Item.Description,
            'PlannedQuantityFactor': line.PlannedQuantityFactor
          };
          if (line.IsBatch) {
            materialLine['BatchNumber'] = line.BatchNumber;
          }
          formattedMaterials.push(materialLine);
        });
        console.log(formattedMaterials);
        axiosClient.post(`api/shoporder/process_shoporder/${id}`, { 'Materials': formattedMaterials, 'amount': produceNumber })
          .then((response) => {
            console.log(response.data);
            navigate(`/shoporderreceipt/details/${response.data['d']['StockTransactionId']}`);
          })
          .catch((error) => {
            console.log(`Error ${error}`);
          }).finally(() => {
            setLoading(false);
          });
      } else {
      axiosClient.post(`api/shoporder/process_shoporder/${id}`, { 'amount': produceNumber })
      .then((response) => {
        console.log(response.data);
        navigate(`/shoporderreceipt/details/${response.data['d']['StockTransactionId']}`);
      })
      .catch((error) => {
        console.log(`Error ${error}`);
      }).finally(() => {
        setLoading(false);
      });
    }
  };

  if (isLoading) {
    return (
      <Loading></Loading>
    );
  }

  return (
    <div className="min-h-full">
      <DetailsBox>
        <DetailsList>
          <DetailsSubtitle>Ordernummer: {shoporder.ShopOrderNumber}</DetailsSubtitle>
          <DetailsTitle>{shoporder.Description}</DetailsTitle>
          <DetailsSubtitle>{shoporder.Item.Description}</DetailsSubtitle>
          <DetailsRow>
            <DetailsElement>
              {
                (shoporder.Status === 10) ?
                  <StatusTextGreen>Open</StatusTextGreen> :
                  (shoporder.Status === 20) ?
                    <StatusTextOrange>Deels verwerkt</StatusTextOrange> :
                    (shoporder.Status === 30) ?
                      <StatusTextBlack>Voltooid</StatusTextBlack> :
                      (shoporder.Status === 40) ?
                        <StatusTextRed>Geannuleerd</StatusTextRed> :
                        <StatusTextGray>Status Onbekend</StatusTextGray>
              }
            </DetailsElement>
            <DetailsElement>
              <ClipboardCheckIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
              {shoporder.ProducedQuantity}/{shoporder.PlannedQuantity} ({shoporder.Item.UnitDescription})
            </DetailsElement>
            <DetailsElement>
              <CubeIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
              Artikel {shoporder.ItemCode}
            </DetailsElement>
          </DetailsRow>
          <DetailsRow>
            <DetailsElement>
              <CalendarIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
              Startdatum {shoporder.PlannedStartDate}
            </DetailsElement>
            <DetailsElement>
              <CalendarIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
              Einddatum {shoporder.PlannedDate}
            </DetailsElement>
          </DetailsRow>
        </DetailsList>
        <DetailsActions>
          <ActionButton action={printStuklijst}>
            <PrinterIcon className="-ml-1 mr-2 h-5 w-5 text-gray-400" aria-hidden="true" />
            Stuklijst
          </ActionButton>
          <ActionButton action={openProduceProducts}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className=" -ml-1 mr-2 w-5 h-5 text-gray-400">
              <path strokeLinecap="round" strokeLinejoin="round" d="M11.42 15.17L17.25 21A2.652 2.652 0 0021 17.25l-5.877-5.877M11.42 15.17l2.496-3.03c.317-.384.74-.626 1.208-.766M11.42 15.17l-4.655 5.653a2.548 2.548 0 11-3.586-3.586l6.837-5.63m5.108-.233c.55-.164 1.163-.188 1.743-.14a4.5 4.5 0 004.486-6.336l-3.276 3.277a3.004 3.004 0 01-2.25-2.25l3.276-3.276a4.5 4.5 0 00-6.336 4.486c.091 1.076-.071 2.264-.904 2.95l-.102.085m-1.745 1.437L5.909 7.5H4.5L2.25 3.75l1.5-1.5L7.5 4.5v1.409l4.26 4.26m-1.745 1.437l1.745-1.437m6.615 8.206L15.75 15.75M4.867 19.125h.008v.008h-.008v-.008z" />
            </svg>
            {(shoporder.IsSerial) ?
              <CreateShopReceiptPopup setOpen={setProduceOpen} open={produceOpen} data={receiptData} setData={setReceiptData} submitForm={produceProducts} label='Produce products'></CreateShopReceiptPopup> :
              (shoporder.IsBatch) ?
              <CreateShopReceiptPopupBatch setNumber={setProduceNumber} number={produceNumber} setOpen={setProduceOpen} open={produceOpen} data={receiptData} setData={setReceiptData} submitForm={produceProducts} label='Produce products'></CreateShopReceiptPopupBatch> :
              <ConfirmNumberPopup setNumber={setProduceNumber} number={produceNumber} setOpen={setProduceOpen} open={produceOpen} submitForm={produceProducts} label='Produce products'></ConfirmNumberPopup>
            }
            Assemble
          </ActionButton>
        </DetailsActions>
      </DetailsBox>
      <ListContainer setSourceItems={setMaterialPlanDetails} rawSourceItems={shoporder.MaterialPlanDetails} title='Materiaal lijst' pageSize={5}>
        <List>
          {materialPlanDetails.map((materialplan) => (
            <Row
              key={materialplan.ID}
              link={`/item/details/${materialplan.Item.ID}`}>
              <RowLine>
                <RowTitle>{materialplan.Item.Description}</RowTitle>
                <RowElement>
                  {
                    (materialplan.Stock >= materialplan.RemainingQuantity) ?
                      <StatusTextGreen>Momenteel op voorraad</StatusTextGreen> :
                      (materialplan.Stock > 0) ?
                        <StatusTextOrange>Deels op voorraad</StatusTextOrange> :
                        <StatusTextRed>Niet op voorraad</StatusTextRed>
                  }
                </RowElement>
              </RowLine>
              <RowLine>
                <RowElement>
                  Lijnnummer: {materialplan.LineNumber}
                </RowElement>
                <RowElement>
                  Huidige voorraad: {materialplan.Stock ?
                    <span>&nbsp;{materialplan.Stock}</span> :
                    <span>&nbsp;0</span>}
                </RowElement>
              </RowLine>
              <RowLine>
                <RowElement>
                  <CalculatorIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                  Aantal per assemblage {materialplan.PlannedQuantityFactor} ({materialplan.UnitDescription})
                </RowElement>
                <RowElement>
                  <ClipboardCheckIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                  Totaal (nog) nodig {materialplan.RemainingQuantity} ({materialplan.UnitDescription})
                </RowElement>
              </RowLine>
            </Row>
          ))}
        </List>
      </ListContainer>
      <ListContainer setSourceItems={setShoporderReceipts} rawSourceItems={shoporder.ShopOrderReceipts} title='Assemblage bonnen' pageSize={5} >
        <List>
          {shoporderReceipts.map((shoporderreceipt) => (
            <Row
              key={shoporderreceipt.ID}
              link={`/shoporderreceipt/details/${shoporderreceipt.ID}`}>
              <RowLine>
                <RowTitle>{shoporderreceipt.Item.Description}</RowTitle>
                <RowElement>Order: {shoporderreceipt.ShopOrderNumber}</RowElement>
              </RowLine>
              <RowLine>
                <RowElement><CubeIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                  Artikel: {shoporderreceipt.Item.Code}
                </RowElement>
                <RowElement>
                  <CalendarIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                  Verwerkingsdatum {shoporderreceipt.Created}
                </RowElement>
              </RowLine>
              <RowLine>
                <RowElement>
                  Verwerkingsnummer: {shoporderreceipt.ReceiptNumber}
                </RowElement>
                <RowElement>
                  Aantal: {shoporderreceipt.Quantity}
                </RowElement>
              </RowLine>
            </Row>
          ))}
        </List>
      </ListContainer>
    </div>
  );
};

export default ShopOrderDetails;
