import React from 'react';
import { purchaseOrderMenu } from '../../../static-data/menus';
import Menu from '../../components/Menu';


const PurchaseOrderMenu = () => {
  return (
    <Menu menuOptions={purchaseOrderMenu}></Menu>
  );
};

export default PurchaseOrderMenu;
