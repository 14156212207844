import React, { useEffect } from 'react';
import { CalendarIcon, LinkIcon } from '@heroicons/react/solid';
import { useState } from 'react';
import Loading from '../../general/Loading';
import { useAxios } from '../../../helpers/AxiosContext';
import { RowElement, Row, RowLine, RowTitle, List, ListContainer } from '../../widgets/ListComponents';
import { StatusTextBlack, StatusTextGray, StatusTextGreen, StatusTextOrange, StatusTextRed } from '../../widgets/StatusText';


const PurchaseOrderList = () => {
  const [isLoading, setLoading] = useState(true);
  const [rawPurchaseOrders, setRawPurchaseOrders] = useState([]);
  const [purchaseOrders, setPurchaseOrders] = useState([]);
  const axiosClient = useAxios();

  useEffect(() => {
    axiosClient.get('api/purchaseorder/get_purchaseorders')
      .then((response) => {
        console.log(response.data);
        setRawPurchaseOrders(response.data);
      })
      .catch((error) => {
        console.log(`Error ${error}`);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  if (isLoading) {
    return (
      <Loading></Loading>
    );
  }

  return (
    <ListContainer rawSourceItems={rawPurchaseOrders} setSourceItems={setPurchaseOrders}>
      <List>
        {purchaseOrders.map((purchaseorder) => (
          <Row
            key={purchaseorder.ID}
            link={`/purchaseorder/details/${purchaseorder.ID}`}>
            <RowLine>
              <RowTitle>{purchaseorder.SupplierName}</RowTitle>
              <RowElement>
                {
                  (purchaseorder.ReceiptStatus === 10) ?
                    <StatusTextGreen>Open</StatusTextGreen> :
                    (purchaseorder.ReceiptStatus === 20) ?
                      <StatusTextOrange>Deels ontvangen</StatusTextOrange> :
                      (purchaseorder.ReceiptStatus === 30) ?
                        <StatusTextBlack>Volledig ontvangen</StatusTextBlack> :
                        (purchaseorder.ReceiptStatus === 40) ?
                          <StatusTextRed>Geannuleerd</StatusTextRed> :
                          <StatusTextGray>Status Onbekend</StatusTextGray>
                }
              </RowElement>
            </RowLine>
            <RowLine>
              <RowElement>Order: {purchaseorder.OrderNumber}</RowElement>
              <RowElement>
                <LinkIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                Referentie:
                {purchaseorder.YourRef ?
                  <span>&nbsp; {purchaseorder.YourRef}</span> :
                  <span>&nbsp; None</span>}
              </RowElement>
            </RowLine>
            <RowLine>
              <RowElement>
                <CalendarIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                Besteldatum {purchaseorder.OrderDate}
              </RowElement>
            </RowLine>
          </Row>
        ))}
      </List>
    </ListContainer>
  );
};

export default PurchaseOrderList;
