import React, { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';


const ConfirmPopup = ({ setConfirm, setOpenConfirm, openConfirm }) => {
  const confirmAction = () => {
    setConfirm();
    setOpenConfirm(false);
  };

  return (
    <Transition.Root show={openConfirm} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpenConfirm}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-gray-200 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <Dialog.Panel
                className="
                  relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden
                  shadow-xl transform transition-all sm:my-8 sm:max-w-xl sm:w-full sm:p-6">
                <div className="bg-blue-700 py-6 px-4 sm:px-6">
                  <div className="flex items-center justify-between">
                    <Dialog.Title className="text-lg font-medium text-white"> Verwijder Product </Dialog.Title>
                    <div className="ml-3 flex h-7 items-center">
                      <button
                        type="button"
                        className="
                          rounded-md bg-blue-700 text-blue-200 hover:text-white
                          focus:outline-none focus:ring-2 focus:ring-white"
                        onClick={() => setOpenConfirm(false)}>
                        <span className="sr-only">Sluit</span>
                        <XIcon className="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                  </div>
                  <div className="mt-1">
                    <p className="text-sm text-blue-300">
                      Weet je zeker dat je het product uit je lijst wilt verwijderen?
                    </p>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6">
                  <button
                    type="button"
                    className="
                      inline-flex justify-center w-full rounded-md border border-transparent
                      shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700
                      focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:text-sm"
                    onClick={() => confirmAction()}>
                    Ja
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default ConfirmPopup;
