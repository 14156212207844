import React from 'react';
import { Outlet } from 'react-router-dom';
import Header from '../general/Header';

const Main = () => {
  return (
    <div className="flex flex-col h-screen">
      <Header></Header>
      <div className='w-full flex-grow'>
        <Outlet />
      </div>
    </div>
  );
};

export default Main;
