import React from 'react';
import { Link } from 'react-router-dom';


const ActionButton = ({ children, action, extraclass='' }) => {
  return (
    <button
      type="button"
      onClick={() => {
        action();
      }}
      className={`inline-flex place-self-start items-center px-4 h-10 py-2 xl:mt-4 border border-gray-300 rounded-md
                  shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50
                  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 
                  focus:ring-purple-500 ${extraclass}`
      }
    >
      {children}
    </button>
  );
};


const LinkButton = ({ children, url, extraclass='' }) => {
  return (
    <Link
      to={url}
      type="button"
      onClick={() => {
        action();
      }}
      className={`inline-flex place-self-start items-center px-4 h-10 py-2 xl:mt-4 border border-gray-300 rounded-md
                  shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50
                  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 
                  focus:ring-purple-500 ${extraclass}`
      }
    >
      {children}
    </Link>
  );
};

export { ActionButton, LinkButton };
