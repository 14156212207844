import React, { Fragment } from 'react';
import { useParams } from 'react-router-dom';
import {
  XIcon,
  PlusCircleIcon,
  CubeIcon,
  TrashIcon
} from '@heroicons/react/solid';
import { useEffect } from 'react';
import { useState } from 'react';
import Loading from '../../general/Loading';
import { useAxios } from '../../../helpers/AxiosContext';
import { Dialog, Transition } from '@headlessui/react';
import ConfirmPopup from '../../widgets/ConfirmPopup';
import { RowElement, Row, RowLine, RowTitle, List, ListContainer } from '../../widgets/ListComponents';
import { DetailsBox, DetailsList, DetailsActions, DetailsTitle, DetailsSubtitle } from '../../widgets/DetailComponents';
import { StatusTextGreen } from '../../../views/widgets/StatusText';
import { ActionButton } from '../../../views/widgets/FancyButtons';


const StorageDetails = () => {
  const { id } = useParams();
  const [isLoading, setLoading] = useState(true);
  const [storage, setStorage] = useState({});
  const [locations, setLocations] = useState([]);
  const [showCreate, setShowCreate] = useState(false);
  const [row, setRow] = useState(1);
  const [shelf, setShelf] = useState(1);
  const [position, setPosition] = useState('A');
  const [openConfirm, setOpenConfirm] = useState(false);
  const [locationDeleteID, setLocationDeleteID] = useState('');
  const axiosClient = useAxios();

  const getStorage = () => {
    setLoading(true);
    axiosClient.get(`api/storage/get_storage/${id}`)
      .then((response) => {
        console.log(response.data);
        setStorage(response.data);
      })
      .catch((error) => {
        console.log(`Error ${error}`);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getStorage();
  }, [id]);

  if (isLoading) {
    return (
      <Loading></Loading>
    );
  }

  const createLocation = (event) => {
    event.preventDefault();
    setLoading(true);
    axiosClient.post('api/location/create_location', { 'storage_id': storage.id, 'row': row, 'shelf': shelf, 'position': position })
      .then((response) => {
        console.log(response.data);
        getStorage();
        setShowCreate(false);
      })
      .catch((error) => {
        console.log(`Error ${error}`);
      }).finally(() => {
        setLoading(false);
      });
  };

  const setConfirmDelete = () => {
    setLoading(true);
    axiosClient.post(`api/location/delete_location/${locationDeleteID}`)
      .then((response) => {
        getStorage();
      })
      .catch((error) => {
        console.log(`Error ${error}`);
      }).finally(() => {
        setLoading(false);
      });
  };

  const createTemplateLocations = () => {
    setLoading(true);
    axiosClient.post('api/location/create_location_template')
      .then((response) => {
        getStorage();
      })
      .catch((error) => {
        console.log(`Error ${error}`);
      }).finally(() => {
        setLoading(false);
      });
  };

  const openCreatePopup = () => {
    setShowCreate(true);
  };

  return (
    <div className="min-h-full">
      <Transition.Root show={showCreate} as={Fragment}>
        <Dialog as="div" className="relative z-20" onClose={setShowCreate}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed z-20 inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-full text-center p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                <Dialog.Panel
                  className="
                        relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden
                        shadow-xl transform transition-all sm:my-8 sm:max-w-xl sm:w-full sm:p-6">
                  <div className="bg-blue-700 py-6 px-4 sm:px-6">
                    <div className="flex items-center justify-between">
                      <Dialog.Title className="text-lg font-medium text-white">Locatie Aanmaken</Dialog.Title>
                      <div className="ml-3 flex h-7 items-center">
                        <button
                          type="button"
                          className="
                                rounded-md bg-blue-700 text-blue-200 hover:text-white
                                focus:outline-none focus:ring-2 focus:ring-white"
                          onClick={() => setShowCreate(false)}>
                          <span className="sr-only">Sluit</span>
                          <XIcon className="h-6 w-6" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-6">
                    <form onSubmit={createLocation} className="space-y-6">
                      <div>
                        <label htmlFor="row" className="block text-sm font-medium text-gray-700">
                          Row
                        </label>
                        <div className="mt-1">
                          <input
                            id="row"
                            name="row"
                            type="number"
                            min='1'
                            max='999'
                            step='1'
                            value={shelf}
                            onChange={(e) => setShelf(e.target.value)}
                            required
                            className="
                                  appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md
                                  shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"/>
                        </div>
                        <label htmlFor="shelf" className="block text-sm font-medium text-gray-700">
                          Shelf
                        </label>
                        <div className="mt-1">
                          <input
                            id="shelf"
                            name="shelf"
                            type="number"
                            min='1'
                            max='999'
                            step='1'
                            value={row}
                            onChange={(e) => setRow(e.target.value)}
                            required
                            className="
                                  appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md
                                  shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"/>
                        </div>
                        <label htmlFor="shelf" className="block text-sm font-medium text-gray-700">
                          Position
                        </label>
                        <div className="mt-1">
                          <input
                            id="position"
                            name="position"
                            type="text"
                            value={position}
                            onChange={(e) => setPosition(e.target.value)}
                            required
                            className="
                                  appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md
                                  shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"/>
                        </div>
                      </div>
                      <div>
                        <button
                          type="submit"
                          className="
                                w-full flex justify-center py-2 px-4 border border-transparent rounded-md
                                shadow-sm text-sm font-medium text-white bg-blue hover:bg-blue-400 focus:outline-none
                                focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                          Create
                        </button>
                      </div>
                    </form>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <DetailsBox>
        <DetailsList>
          <DetailsSubtitle>Opslagnummer: {storage.Number}</DetailsSubtitle>
          <DetailsTitle>{storage.Name}</DetailsTitle>
          {(storage.Default === true) &&
            <DetailsSubtitle>
              <StatusTextGreen>Default opslag</StatusTextGreen>
            </DetailsSubtitle>
          }
        </DetailsList>
        <DetailsActions>
          <ActionButton
            action={createTemplateLocations}>
            <PlusCircleIcon className="flex-shrink-0 mr-1 l-1 h-5 w-5 text-gray-300 group-hover:text-gray-100" aria-hidden="true" />
            Maak template locaties
          </ActionButton>
          <ActionButton
            action={openCreatePopup}>
            <PlusCircleIcon className="flex-shrink-0 mr-1 l-1 h-5 w-5 text-gray-300 group-hover:text-gray-100" aria-hidden="true" />
            Maak nieuwe locatie
          </ActionButton>
        </DetailsActions>
      </DetailsBox>
      <ListContainer setSourceItems={setLocations} rawSourceItems={storage.Locations} title='Opslag locaties' pageSize={5}>
        <ConfirmPopup setConfirm={setConfirmDelete} setOpenConfirm={setOpenConfirm} openConfirm={openConfirm}></ConfirmPopup>
        <List>
          {locations.map((location) => (
            <Row
              key={location.ID}
              link={`/location/details/${location.ID}`}>
              <RowLine>
                <RowTitle>{location.Barcode}</RowTitle>
                <RowElement>Locatie: ({location.Row} , {location.Shelf} , {location.Position})</RowElement>
              </RowLine>
              <RowLine>
                <RowElement>
                  {location.ExactObjects.map((exactobject) => (
                    <span
                      key={exactobject.ID}
                      className="flex">
                      <CubeIcon className="-ml-1 mr-2 h-5 w-5 text-gray-400" aria-hidden="true" />
                      {exactobject.ExactID}
                    </span>
                  ))}
                </RowElement>
                <RowElement>
                  <button
                    onClick={(event) => {
                      event.preventDefault();
                      setLocationDeleteID(location.id);
                      setOpenConfirm(true);
                    }}
                    className="flex">
                    <TrashIcon className="-ml-1 mr-2 h-5 w-5 text-gray-400 hover:text-black" aria-hidden="true" />
                  </button>
                </RowElement>
              </RowLine>
            </Row>
          ))}
        </List>
      </ListContainer>
    </div>
  );
};

export default StorageDetails;
