import React from 'react';
import { CookiesProvider } from 'react-cookie';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import App from './App';
import { AxiosProvider } from './helpers/AxiosContext';
import store from './helpers/store/configureStore';
import './index.css';


ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <CookiesProvider>
        <Provider store={store.store}>
          <PersistGate loading={null} persistor={store.persistor}>
            <AxiosProvider>
              <App />
            </AxiosProvider>
          </PersistGate>
        </Provider>
      </CookiesProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

