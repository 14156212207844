import {
  CalendarIcon, ClipboardCheckIcon,
  CubeIcon, LinkIcon, PrinterIcon
} from '@heroicons/react/solid';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAxios } from '../../../helpers/AxiosContext';
import ConfirmNumberPopup from '../../components/ConfirmNumberPopup';
import Loading from '../../general/Loading';
import { DetailsActions, DetailsBox, DetailsElement, DetailsList, DetailsRow, DetailsSubtitle, DetailsTitle } from '../../widgets/DetailComponents';
import { LinkButton } from '../../widgets/FancyButtons';
import { List, ListContainer, Row, RowElement, RowLine, RowTitle } from '../../widgets/ListComponents';


const GoodsReceiptDetails = () => {
  const { id } = useParams();
  const [isLoading, setLoading] = useState(true);
  const [goodsreceipt, setGoodsreceipt] = useState({});
  const [goodsReceiptLines, setGoodsReceiptLines] = useState([]);
  const [printNumber, setPrintNumber] = useState(1);
  const [printOpen, setPrintOpen] = useState(false);
  const [goodsreciptlineID, setGoodsreceiptlineID] = useState('');
  const axiosClient = useAxios();

  useEffect(() => {
    axiosClient.get(`api/purchaseorder/get_goodsreceipt/${id}`)
      .then((response) => {
        console.log(response.data);
        setGoodsreceipt(response.data);
      })
      .catch((error) => {
        console.log(`Error ${error}`);
      }).finally(() => {
        setLoading(false);
      });
  }, [id]);

  if (isLoading) {
    return (
      <Loading></Loading>
    );
  }

  const printLabels = () => {
    setLoading(true);
    axiosClient.post(`api/printing/label/goodsreceiptline/${goodsreciptlineID}`, { 'amount': printNumber })
      .then((response) => {
        console.log(response.data);
        dispatch(allActions.notificationActions.setSuccess({
          'title': 'Print Succesvol',
          'message': 'Alle labels succesvol geprint!'
        }));
      })
      .catch((error) => {
        console.log(`Error ${error}`);
      }).finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="min-h-full">
      <DetailsBox>
        <DetailsList>
          <DetailsSubtitle>Onvangstnummer: {goodsreceipt.ReceiptNumber}</DetailsSubtitle>
          <DetailsTitle>{goodsreceipt.SupplierName}</DetailsTitle>
          <DetailsSubtitle>{goodsreceipt.Description}</DetailsSubtitle>
          <DetailsRow>
            <DetailsElement>
              <LinkIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
              Referentie:
              {(goodsreceipt.YourRef) ?
                <span>&nbsp; {goodsreceipt.YourRef}</span> :
                <span>&nbsp; None</span>}
            </DetailsElement>
            <DetailsElement>
              <CalendarIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
              Ontvangstdatum {goodsreceipt.ReceiptDate}
            </DetailsElement>
          </DetailsRow>
          <DetailsRow>
            <DetailsElement>Bestellingnummer: {goodsreceipt.PurchaseOrderNumber}</DetailsElement>
          </DetailsRow>
        </DetailsList>
        <DetailsActions>
          {(goodsreceipt.PurchaseOrderIDs.length === 1) ?
            <LinkButton url={`/purchaseorder/details/${goodsreceipt.PurchaseOrderIDs[0]}`}>
              <LinkIcon className="-ml-1 mr-2 h-5 w-5 text-gray-400" aria-hidden="true" />
              Bekijk bestelling
            </LinkButton> :
            <DetailsSubtitle>{goodsreceipt.PurchaseOrderNumbers}</DetailsSubtitle>}
        </DetailsActions>
      </DetailsBox>
      <ListContainer setSourceItems={setGoodsReceiptLines} rawSourceItems={goodsreceipt.GoodsReceiptLines} title='Materiaal lijst' pageSize={5}>
        <List>
          {goodsReceiptLines.map((goodsreceiptline) => (
            <Row
              key={goodsreceiptline.ID}
              link={`/goodsreceiptline/details/${goodsreceiptline.ID}`}>
              <RowLine>
                <RowTitle>{goodsreceiptline.Item.Description}</RowTitle>
                <RowElement>Lijnnummer: {goodsreceiptline.LineNumber}</RowElement>
              </RowLine>
              <RowLine>
                <RowElement>
                  <CubeIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                  Artikel: {goodsreceiptline.Item.Code}
                </RowElement>
                <RowElement>
                  <ClipboardCheckIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                  {goodsreceiptline.QuantityReceived}/{goodsreceiptline.QuantityOrdered}
                </RowElement>
              </RowLine>
              <RowLine>
                <RowElement>
                  <button
                    type="button"
                    onClick={(event) => {
                      event.preventDefault();
                      setGoodsreceiptlineID(goodsreceiptline.ID);
                      setPrintNumber(1);
                      setPrintOpen(true);
                    }}
                    className="
                          inline-flex items-center px-4 py-1 h-10 border border-gray-300 rounded-md
                          shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50
                          focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-purple-500"
                  >
                    <PrinterIcon className="-ml-1 mr-2 h-5 w-5 text-gray-400" aria-hidden="true" />
                    <ConfirmNumberPopup setNumber={setPrintNumber} number={printNumber} setOpen={setPrintOpen} open={printOpen} submitForm={printLabels} label='Print labels'></ConfirmNumberPopup>
                    Labels
                  </button>
                </RowElement>
              </RowLine>
            </Row>
          ))}
        </List>
      </ListContainer>
    </div>
  );
};

export default GoodsReceiptDetails;
