import React from 'react';
import { PlusCircleIcon, XIcon } from '@heroicons/react/outline';

const LineRegular = ({ line, index, lines, setLines }) => {
  return (
    <div className="mt-1">
      <input
        name="number"
        type="number"
        min="0"
        value={line.value}
        onChange={(e) => {
          const newLines = [...lines];
          newLines[index].value = e.target.value;
          setLines(newLines);
        }}
        className="
      appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md
      shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"/>
    </div>
  );
};

const LineBatch = ({ line, index, lines, setLines }) => {
  const addBatchLine = () => {
    const newLines = [...lines];
    newLines[index].batchnumbers.push({ 'BatchNumber': '', 'Quantity': 1 });
    setLines(newLines);
  };

  return (
    <div className="">
      {line.batchnumbers.map((batchnumber, batchindex) => (
        <div
          key={batchindex}>
          <div className="mt-1">
            <input
              name="batchnumber"
              type="text"
              value={batchnumber.BatchNumber}
              placeholder='Batch Code'
              onChange={(e) => {
                const newLines = [...lines];
                newLines[index].batchnumbers[batchindex].BatchNumber = e.target.value;
                setLines(newLines);
              }}
              className="
                appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md
                shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"/>
          </div>
          <button
            type="button"
            className='absolute right-8 -mt-8 text-sm font-medium text-gray-700'
            onClick={() => {
              const newLines = [...lines];
              newLines[index].batchnumbers.splice(batchindex, 1);
              setLines(newLines);
            }}>
            <XIcon className="h-6 w-6" aria-hidden="true" />
          </button>
          <div className="mt-1">
            <input
              name="batchquantity"
              type="number"
              min="1"
              value={batchnumber.Quantity}
              onChange={(e) => {
                const newLines = [...lines];
                newLines[index].batchnumbers[batchindex].Quantity = e.target.value;
                setLines(newLines);
              }}
              className="
                appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md
                shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"/>
          </div>
        </div>
      ))}
      <button
        type="button"
        className='flex mx-auto items-center text-sm font-medium text-gray-700'
        onClick={addBatchLine}>
        Meer Batch Codes<PlusCircleIcon className="h-6 w-6" aria-hidden="true" />
      </button>
    </div>
  );
};

const LineSerial = ({ line, index, lines, setLines }) => {
  const addSerialLine = () => {
    const newLines = [...lines];
    newLines[index].serialnumbers.push('');
    setLines(newLines);
  };

  return (
    <div className="">
      {line.serialnumbers.map((serialnumber, serialindex) => (
        <div
          key={serialindex}>
          <div className="mt-1">
            <input
              name="searialnumber"
              type="text"
              value={serialnumber}
              placeholder='Serial Code'
              onChange={(e) => {
                const newLines = [...lines];
                newLines[index].serialnumbers[serialindex] = e.target.value;
                setLines(newLines);
              }}
              className="
                  appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md
                  shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"/>
          </div>
          <button
            type="button"
            className='absolute right-8 -mt-8 text-sm font-medium text-gray-700'
            onClick={() => {
              const newLines = [...lines];
              newLines[index].serialnumbers.splice(serialindex, 1);
              setLines(newLines);
            }}>
            <XIcon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
      ))}
      <button
        type="button"
        className='flex mx-auto items-center text-sm font-medium text-gray-700'
        onClick={addSerialLine}>
        Meer Serial Codes<PlusCircleIcon className="h-6 w-6" aria-hidden="true" />
      </button>
    </div>
  );
};

export { LineRegular, LineBatch, LineSerial };
