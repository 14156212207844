import React from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAxios } from '../../helpers/AxiosContext';
import Loading from './Loading';


const Home = () => {
  const [isLoading, setLoading] = useState(false);
  const [barcode, setBarcode] = useState('');
  const axiosClient = useAxios();
  const navigate = useNavigate();

  const searchBarcode = () => {
    setLoading(true);
    axiosClient.get(`api/general/search_barcode/${barcode}`)
    .then((response) => {
      if (response.data.type == 'Item') {
        navigate(`/item/details/${response.data.ID}`);
      } else if (response.data.type == 'ShopOrder') {
        navigate(`/shoporder/details/${response.data.ID}`);
      } else if (response.data.type == 'PurchaseOrder') {
        navigate(`/purchaseorder/details/${response.data.ID}`);
      } else if (response.data.type == 'SalesOrder') {
        navigate(`/salesorder/details/${response.data.ID}`);
      } else if (response.data.type == 'GoodsReceipt') {
        navigate(`/goodsreceipt/details/${response.data.ID}`);
      } else if (response.data.type == 'GoodsDelivery') {
        navigate(`/goodsdelivery/details/${response.data.ID}`);
      } else if (response.data.type == 'Location') {
        navigate(`/location/details/${response.data.ID}`);
      } else if (response.data.type == 'ExactObject') {
        navigate(`/exactobject/details/${response.data.ID}`);
      }
    })
    .catch((error) => {
      console.log(`Error ${error}`);
    }).finally(() => {
      setLoading(false);
    });
  };

  if (isLoading) {
    return (
      <Loading></Loading>
    );
  }

  return (
    <div className="min-h-full">
      <div className="bg-white py-8 sm:py-8">
        <div className="relative sm:py-4">
          <div aria-hidden="true" className="hidden sm:block">
            <div className="absolute inset-y-0 left-0 w-1/2 bg-gray-50 rounded-r-3xl" />
            <svg className="absolute top-1 left-1/2 -ml-3" width={404} height={392} fill="none" viewBox="0 0 404 392">
              <defs>
                <pattern
                  id="8228f071-bcee-4ec8-905a-2a059a2cc4fb"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                </pattern>
              </defs>
              <rect width={404} height={232} fill="url(#8228f071-bcee-4ec8-905a-2a059a2cc4fb)" />
            </svg>
          </div>
          <div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8">
            <div className="relative rounded-2xl px-6 py-6 bg-blue-600 overflow-hidden shadow-xl sm:px-12 sm:py-10">
              <div aria-hidden="true" className="absolute inset-0 -mt-72 sm:-mt-32 md:mt-0">
                <svg
                  className="absolute inset-0 h-full w-full"
                  preserveAspectRatio="xMidYMid slice"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 1463 360"
                >
                  <path
                    className="text-blue-400 text-opacity-40"
                    fill="currentColor"
                    d="M-82.673 72l1761.849 472.086-134.327 501.315-1761.85-472.086z"
                  />
                  <path
                    className="text-blue-300 text-opacity-40"
                    fill="currentColor"
                    d="M-217.088 544.086L1544.761 72l134.327 501.316-1761.849 472.086z"
                  />
                </svg>
              </div>
              <div className="relative">
                <div className="sm:text-center">
                  <h2 className="text-3xl font-extrabold text-white tracking-tight sm:text-4xl">
                    Zoek direct
                  </h2>
                </div>
                <form action="#" className="mt-8 sm:mx-auto sm:max-w-lg sm:flex">
                  <div className="min-w-0 flex-1">
                    <label htmlFor="cta-email" className="sr-only">
                      Email address
                    </label>
                    <input
                      id="barcode"
                      type="text"
                      value={barcode}
                      onChange={(e) => setBarcode(e.target.value)}
                      className="
                        block w-full border border-transparent rounded-md px-5 py-3
                        text-base text-gray-900 placeholder-gray-500 shadow-sm focus:outline-none
                        focus:border-transparent focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-blue-600"
                      placeholder="Scan barcode"
                    />
                  </div>
                  <div className="mt-4 sm:mt-0 sm:ml-3">
                    <button
                      type="submit"
                      onClick={ () => {
                        searchBarcode();
                      }}
                      className="
                        block w-full rounded-md border border-transparent px-5 py-3 bg-blue-500
                        text-base font-medium text-white shadow hover:bg-blue-400 focus:outline-none
                        focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-blue-600 sm:px-10"
                    >
                      Search
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;

