import { ArchiveIcon, ClipboardCheckIcon, ClipboardListIcon, TruckIcon } from '@heroicons/react/outline';

const links = [
  { title: 'Assemblage', description: 'Overzicht voor assemblages mogelijkheden', icon: ClipboardListIcon, link: '/shoporder' },
  { title: 'Ontvangen', description: 'Alles met betrekking tot goederen ontvangst', icon: ClipboardCheckIcon, link: '/purchaseorder' },
  { title: 'Verzenden', description: 'Alles met betrekking tot goederen verzending', icon: TruckIcon, link: '/salesorder' },
  { title: 'Opslag', description: 'Bekijk de huide opslag', icon: ArchiveIcon, link: '/storage' },
];

export { links };
