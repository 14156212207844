import React from 'react';
import PopupWindow from '../widgets/PopupWindow';

const CreateShopReceiptPopupBatch = ({ setOpen, open, data, setData, number, setNumber, submitForm, label }) => {
  return (
    <PopupWindow setOpen={setOpen} open={open} submitForm={submitForm} title={label}>
      <div>
        <label htmlFor="number" className="block text-sm font-medium text-gray-700">
          Totaal gemaakte assemblages
        </label>
        <div className="mt-1">
          <input
            id="number"
            name="number"
            type="number"
            value={number}
            onChange={(e) => setNumber(e.target.value)}
            className="
              appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md
              shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"/>
        </div>
      </div>
      <label className="block text-sm font-medium text-gray-700">
        Let op ondereel batchummer moet hetzelfde zijn voor alle gemaakte assemblages
      </label>
      {data.map((line, index) => (
        <div
          key={index}>
          {line.IsBatch &&
            <div>
              <p className="flex items-center text-sm font-medium text-gray-700">
                {line.Item.Description}
              </p>
              <div
                className="mt-1">
                <input
                  name="searialnumber"
                  type="text"
                  value={line.BatchNumber}
                  placeholder='Batchnummer'
                  onChange={(e) => {
                    const newLines = [...data];
                    newLines[index].BatchNumber = e.target.value;
                    setData(newLines);
                  }}
                  className="
                    appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md
                    shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"/>
              </div>
            </div>
          }
        </div>
      ))}
    </PopupWindow>
  );
};

export default CreateShopReceiptPopupBatch;
