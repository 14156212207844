import React, { useEffect } from 'react';
import { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAxios } from '../../../helpers/AxiosContext';
import Loading from '../../general/Loading';

const Exact = () => {
  const [searchParams] = useSearchParams();
  const code = searchParams.get('code');
  const navigate = useNavigate();
  const axiosClient = useAxios();
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    if (code) {
      setLoading(true);
      axiosClient.post('api/authentication/exact_code', { 'code': code })
        .then((response) => {
          navigate('/');
        })
        .catch((error) => {
          console.log(`Error ${error}`);
        }).finally(() => {
          setLoading(false);
        });
    }
  }, []);

  if (isLoading) {
    return (
      <Loading></Loading>
    );
  }

  return (
    <div className="min-h-full">
      <a href="https://start.exactonline.nl/api/oauth2/auth?client_id=759d3f2e-fff4-4881-b91d-69ff47bb1008&redirect_uri=https://sbctesting.nl/exact/authentication&response_type=code&force_login=1">
        Login Exact
      </a>
    </div>
  );
};

export default Exact;
