const notificationState = (state = {
  error_message: {},
  error_state: false,
  info_message: {},
  info_state: false,
  warning_message: {},
  warning_state: false,
  success_message: {},
  success_state: false
}, action) => {
  switch (action.type) {
    case 'SET_ERROR':
      return {
        ...state,
        error_message: action.payload,
        error_state: true
      };
    case 'SET_INFO':
      return {
        ...state,
        info_message: action.payload,
        info_state: true
      };
    case 'SET_WARNING':
      return {
        ...state,
        warning_message: action.payload,
        warning_state: true
      };
    case 'SET_SUCCESS':
      return {
        ...state,
        success_message: action.payload,
        success_state: true
      };
    case 'CLEAR_ERROR':
      return {
        ...state,
        error_message: {},
        error_state: false
      };
    case 'CLEAR_INFO':
      return {
        ...state,
        info_message: {},
        info_state: false
      };
    case 'CLEAR_WARNING':
      return {
        ...state,
        warning_message: {},
        warning_state: false
      };
    case 'CLEAR_SUCCESS':
      return {
        ...state,
        success_message: {},
        success_state: false
      };
    default:
      return state;
  };
};

export default notificationState;
