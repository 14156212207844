import React, { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useAxios } from '../../../helpers/AxiosContext';
import allActions from '../../../helpers/store/actions';
import Loading from '../../general/Loading';

const Division = () => {
  const navigate = useNavigate();
  const [divisions, setDivisions] = useState([]);
  const [selectedDivision, setSelectedDivision] = useState('');
  const dispatch = useDispatch();
  const axiosClient = useAxios();
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    axiosClient.get('api/authentication/get_divisions')
      .then((response) => {
        console.log(response.data);
        setDivisions(response.data);
        response.data.forEach((devision) => {
          if (devision['Current'] === true) {
            setSelectedDivision(devision.Division);
          };
        });
      })
      .catch((error) => {
        console.log(`Error ${error}`);
      }).finally(() => {
        setLoading(false);
      });
  }, []);

  const saveDivision = (event) => {
    event.preventDefault();
    setLoading(true);
    axiosClient.post('api/authentication/set_division', { 'division': selectedDivision })
      .then((response) => {
        dispatch(allActions.notificationActions.setSuccess({
          'title': 'Success',
          'message': 'Successfully updated division!'
        }));
        navigate('/');
      })
      .catch((error) => {
        console.log(`Error ${error}`);
        setLoading(false);
      });
  };

  if (isLoading) {
    return (
      <Loading></Loading>
    );
  }

  return (
    <div className="min-h-full max-w-7xl mx-auto">
      <div className="bg-white overflow-hidden shadow rounded-lg p-5 mt-5">
        <div>
          <h3 className="text-lg leading-6 font-medium text-gray-900">Exact Omgeving</h3>
          <p className="mt-1 max-w-2xl text-sm text-gray-500">
            Verander hier de exact omgeving.
          </p>
        </div>
        <form onSubmit={saveDivision} className="space-y-4 divide-y divide-gray-200">
          <div className="mt-6">
            {(divisions.length) ? (
              <select
                id="division"
                name="division"
                value={selectedDivision}
                className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                onChange={(e) => setSelectedDivision(e.target.value)}
              >
                {divisions.map((division) => (
                  <option key={division.Division} value={division.Division}>{division.Description}</option>
                ))}
              </select>
            ) : (
              <div>
                No divisions found!
              </div>
            )}
          </div>
          <button
            type="submit"
            className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-500 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            Save
          </button>
        </form>
      </div>
    </div>
  );
};

export default Division;
