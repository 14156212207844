import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAxios } from '../../helpers/AxiosContext';
import allActions from '../../helpers/store/actions';
import logoFull from '../../images/logo.jpg';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const axiosClient = useAxios();
  const dispatch = useDispatch();

  const login = (event) => {
    event.preventDefault();
    axiosClient.post('api/authentication/login', { 'email': email, 'password': password })
      .then((response) => {
        dispatch(allActions.userActions.setUser(response.data));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="min-h-full flex">
      <div className="flex-1 flex flex-col justify-center py-12 px-4">
        <div className="mx-auto w-full max-w-sm lg:w-96">
          <div className="w-full">
            <img
              className="h-52 mx-auto"
              src={logoFull}
              alt="Cadeautje.me"
            />
          </div>

          <div className="mt-8">
            {/* view email login */}
            <div className="mt-6">
              <form onSubmit={login} className="space-y-6">
                <div>
                  <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                    E-mailadres
                  </label>
                  <div className="mt-1">
                    <input
                      id="email"
                      name="email"
                      type="email"
                      placeholder="Email"
                      value={email}
                      autoComplete="email"
                      onChange={(e) => setEmail(e.target.value)}
                      required
                      className="
                        appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md
                        shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"/>
                  </div>
                  <label htmlFor="password" className="mt-2 block text-sm font-medium text-gray-700">
                    Wachtwoord
                  </label>
                  <div className="mt-1">
                    <input
                      id="password"
                      name="password"
                      type="password"
                      placeholder="Wachtwoord"
                      value={password}
                      autoComplete="password"
                      onChange={(e) => setPassword(e.target.value)}
                      required
                      className="
                        appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md
                        shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"/>
                  </div>
                </div>
                <div>
                  <button
                    type="submit"
                    className="
                      w-full flex justify-center py-2 px-4 border border-transparent rounded-md
                      shadow-sm text-sm font-medium text-white bg-blue hover:bg-blue-400 focus:outline-none
                      focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 cursor:'pointer'">
                    Log in
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
