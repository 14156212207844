import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Routes, useLocation } from 'react-router-dom';
import Home from './views/general/Home';
import Login from './views/general/Login';
import Main from './views/general/Main';
import NotFound from './views/general/NotFound';
import Devision from './views/pages/Exact/Devision';
import Exact from './views/pages/Exact/Exact';
import Subscriptions from './views/pages/Exact/Subscriptions';
import { SuccessPopup, ErrorPopup, InfoPopup, WarningPopup } from './views/widgets/NotificationPopup';
import SalesOrderMenu from './views/pages/SalesOrder/SalesOrderMenu';
import SalesOrderDetails from './views/pages/SalesOrder/SalesOrderDetails';
import SalesOrderList from './views/pages/SalesOrder/SalesOrderList';
import GoodsDeliveryDetails from './views/pages/SalesOrder/GoodsDeliveryDetails';
import GoodsDeliveryList from './views/pages/SalesOrder/GoodsDeliveryList';
import PurchaseOrderDetails from './views/pages/PurchaseOrder/PurchaseOrderDetails';
import PurchaseOrderList from './views/pages/PurchaseOrder/PurchaseOrderList';
import PurchaseOrderMenu from './views/pages/PurchaseOrder/PurchaseOrderMenu';
import GoodsReceiptDetails from './views/pages/PurchaseOrder/GoodsReceiptDetails';
import GoodsReceiptList from './views/pages/PurchaseOrder/GoodsReceiptList';
import ShopOrderDetails from './views/pages/ShopOrder/ShopOrderDetails';
import ShopOrderList from './views/pages/ShopOrder/ShopOrderList';
import ShopOrderMenu from './views/pages/ShopOrder/ShopOrderMenu';
import ShopOrderReceiptList from './views/pages/ShopOrder/ShopOrderReceiptList';
import ItemDetails from './views/pages/Item/ItemDetails';
import ItemList from './views/pages/Item/ItemList';
import StorageDetails from './views/pages/Storage/StorageDetails';
import StorageList from './views/pages/Storage/StorageList';
import StorageMenu from './views/pages/Storage/StorageMenu';
import LocationDetails from './views/pages/Storage/LocationDetails';
import ShopOrderReceiptDetails from './views/pages/ShopOrder/ShoporderReceiptDetails';
import GoodsReceiptLineDetails from './views/pages/PurchaseOrder/GoodsReceiptLineDetails';
import { useAxios } from './helpers/AxiosContext';
import { useEffect } from 'react';
import { useState } from 'react';
import Loading from './views/general/Loading';


const App = () => {
  const user = useSelector((state) => state.currentUser);
  const [isLoading, setLoading] = useState(true);
  const axiosClient = useAxios();
  const { pathname } = useLocation();

  useEffect(() => {
    setLoading(true);
    axiosClient.get('api/authentication/verify_authentication')
      .then((response) => {
      })
      .catch((error) => {
        console.log(`Error ${error}`);
      }).finally(() => {
        setLoading(false);
      });
  }, [pathname]);

  if (isLoading) {
    return (
      <Loading></Loading>
    );
  }

  return (
    <div className="h-full">
      <ErrorPopup></ErrorPopup>
      <InfoPopup></InfoPopup>
      <WarningPopup></WarningPopup>
      <SuccessPopup></SuccessPopup>
      <Routes>
        {user.loggedIn ? (
          <>
            <Route path='/' element={<Main />}>
              <Route exact path='/' element={<Home />}></Route>
              {/* Assemblage Views */}
              <Route exact path='/shoporder' element={<ShopOrderMenu />}></Route>
              <Route exact path='/shoporder/list' element={<ShopOrderList />}></Route>
              <Route exact path='/shoporder/details/:id' element={<ShopOrderDetails />}></Route>
              <Route exact path='/shoporderreceipt/list' element={<ShopOrderReceiptList />}></Route>
              <Route exact path='/shoporderreceipt/details/:id' element={<ShopOrderReceiptDetails />}></Route>
              {/* Ontvangst Views */}
              <Route exact path='/purchaseorder' element={<PurchaseOrderMenu />}></Route>
              <Route exact path='/purchaseorder/list' element={<PurchaseOrderList />}></Route>
              <Route exact path='/purchaseorder/details/:id' element={<PurchaseOrderDetails />}></Route>
              <Route exact path='/goodsreceipt/list' element={<GoodsReceiptList />}></Route>
              <Route exact path='/goodsreceipt/details/:id' element={<GoodsReceiptDetails />}></Route>
              <Route exact path='/goodsreceiptline/details/:id' element={<GoodsReceiptLineDetails />}></Route>
              {/* Verzend Views */}
              <Route exact path='/salesorder' element={<SalesOrderMenu />}></Route>
              <Route exact path='/salesorder/list' element={<SalesOrderList />}></Route>
              <Route exact path='/salesorder/details/:id' element={<SalesOrderDetails />}></Route>
              <Route exact path='/goodsdelivery/list' element={<GoodsDeliveryList />}></Route>
              <Route exact path='/goodsdelivery/details/:id' element={<GoodsDeliveryDetails />}></Route>
              {/* Opslag Views */}
              <Route exact path='/storage' element={<StorageMenu />}></Route>
              <Route exact path='/storage/list' element={<StorageList />}></Route>
              <Route exact path='/storage/details/:id' element={<StorageDetails />}></Route>
              <Route exact path='/location/details/:id' element={<LocationDetails />}></Route>
              <Route exact path='/item/list' element={<ItemList />}></Route>
              <Route exact path='/item/details/:id' element={<ItemDetails />}></Route>
              {/* Administration Views */}
              <Route exact path='/exact/authentication' element={<Exact />}></Route>
              <Route exact path='/devision' element={<Devision />}></Route>
              <Route exact path='/subscriptions' element={<Subscriptions />}></Route>
            </Route>
          </>
        ) : (
          <>
            <Route path='/*' element={<Login />}></Route>
          </>
        )}
        <Route exact path='/*' element={<NotFound />}></Route>
      </Routes>
    </div>
  );
};

export default App;
