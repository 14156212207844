import React from 'react';

const DetailsTitle = ({ children }) => {
  return (
    <h1 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
      {children}
    </h1>
  );
};

const DetailsSubtitle = ({ children }) => {
  return (
    <h1 className="text-gray-700 sm:truncate">
      {children}
    </h1>
  );
};

const DetailsElement = ({ children }) => {
  return (
    <div className="mt-2 flex items-center text-sm text-gray-500">
      <p className="flex items-center text-sm text-gray-500">
        {children}
      </p>
    </div>
  );
};

const DetailsRow = ({ children }) => {
  return (
    <div className="mt-1 flex flex-col sm:flex-row sm:flex-wrap sm:mt-0 sm:space-x-8">
      {children}
    </div>
  );
};

const DetailsList = ({ children }) => {
  return (
    <div className="flex-1 mt-4 mb-4 min-w-0">
      {children}
    </div>
  );
};

const DetailsActions = ({ children }) => {
  return (
    <div className="mt-1 mb-4 flex flex-col sm:flex-row sm:flex-wrap space-y-4 sm:space-y-0 xl:space-y-4 sm:space-x-4">
      {children}
    </div>
  );
};

const DetailsBox = ({ children }) => {
  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 mt-12 mb-4">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 xl:flex xl:items-center xl:justify-between shadow overflow-hidden sm:rounded-md">
        {children}
      </div>
    </div>
  );
};

export { DetailsBox, DetailsActions, DetailsList, DetailsRow, DetailsElement, DetailsTitle, DetailsSubtitle };
