import { PrinterIcon } from '@heroicons/react/outline';
import {
  CalendarIcon, CubeIcon, LinkIcon
} from '@heroicons/react/solid';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAxios } from '../../../helpers/AxiosContext';
import ConfirmNumberPopup from '../../components/ConfirmNumberPopup';
import Loading from '../../general/Loading';
import { DetailsActions, DetailsBox, DetailsElement, DetailsList, DetailsRow, DetailsSubtitle, DetailsTitle } from '../../widgets/DetailComponents';
import { ActionButton, LinkButton } from '../../widgets/FancyButtons';
import { List, ListContainer, Row, RowElement, RowLine, RowTitle } from '../../widgets/ListComponents';


const ShopOrderReceiptDetails = () => {
  const { id } = useParams();
  const [isLoading, setLoading] = useState(true);
  const [shopOrderReceipt, setShopOrderReceipt] = useState({});
  const [materials, setMaterials] = useState([]);
  const axiosClient = useAxios();
  const [printNumber, setPrintNumber] = useState(1);
  const [printOpen, setPrintOpen] = useState(false);

  useEffect(() => {
    axiosClient.get(`api/shoporder/get_shoporder_receipt/${id}`)
      .then((response) => {
        console.log(response.data);
        setShopOrderReceipt(response.data);
      })
      .catch((error) => {
        console.log(`Error ${error}`);
      }).finally(() => {
        setLoading(false);
      });
  }, [id]);

  const printBarcodeLabel = () => {
    setLoading(true);
    if (shopOrderReceipt.IsSerial) {
      axiosClient.post('api/printing/serie_batch', {
        'serie_batch_numbers': [shopOrderReceipt.SerialNumber],
        'name': shopOrderReceipt.Item.Description
      })
        .then((response) => {
          console.log(response.data);
        })
        .catch((error) => {
          console.log(`Error ${error}`);
        }).finally(() => {
          setLoading(false);
        });
    } else if (shopOrderReceipt.IsBatch) {
      axiosClient.post('api/printing/serie_batch', {
        'serie_batch_numbers': [shopOrderReceipt.BatchNumber],
        'name': shopOrderReceipt.Item.Description
      })
        .then((response) => {
          console.log(response.data);
        })
        .catch((error) => {
          console.log(`Error ${error}`);
        }).finally(() => {
          setLoading(false);
        });
    }
  };

  const printAssemblageObjectLabel = () => {
    setLoading(true);
    axiosClient.post(`api/printing/label/shoporderreceipt/${id}`, { 'amount': printNumber })
      .then((response) => {
        console.log(response.data);
        dispatch(allActions.notificationActions.setSuccess({
          'title': 'Print Succesvol',
          'message': 'Alle labels succesvol geprint!'
        }));
      })
      .catch((error) => {
        console.log(`Error ${error}`);
      }).finally(() => {
        setLoading(false);
      });
  };

  if (isLoading) {
    return (
      <Loading></Loading>
    );
  }

  return (
    <div className="min-h-full">
      <DetailsBox>
        <DetailsList>
          <DetailsSubtitle>Assemblage Bonnummer: {shopOrderReceipt.ReceiptNumber}</DetailsSubtitle>
          <DetailsTitle>{shopOrderReceipt.Item.Description}</DetailsTitle>
          {shopOrderReceipt.IsSerial &&
            <DetailsSubtitle>Serienummer: {shopOrderReceipt.SerialNumber}</DetailsSubtitle>
          }
          {shopOrderReceipt.IsBatch &&
            <DetailsSubtitle>Batchnummer: {shopOrderReceipt.BatchNumber}</DetailsSubtitle>
          }
          <DetailsRow>
            <DetailsElement>
              <CalendarIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
              Assemblagedatum {shopOrderReceipt.Created}
            </DetailsElement>
          </DetailsRow>
          <DetailsRow>
            <DetailsElement>Ordernummer: {shopOrderReceipt.ShopOrderNumber}</DetailsElement>
          </DetailsRow>
        </DetailsList>
        <DetailsActions>
          <LinkButton url={`/shoporder/details/${shopOrderReceipt.ShopOrderID}`}>
            <LinkIcon className="-ml-1 mr-2 h-5 w-5 text-gray-400" aria-hidden="true" />
            Volledige assemblage
          </LinkButton>
        </DetailsActions>
      </DetailsBox>
      <DetailsBox>
        <DetailsActions>
          <ActionButton
            action={printBarcodeLabel}>
            <PrinterIcon className="-ml-1 mr-2 h-5 w-5 text-gray-400" aria-hidden="true" />
            Print Barcode
          </ActionButton>
          <button
            type="button"
            onClick={() => {
              setPrintNumber(1);
              setPrintOpen(true);
            }}
            className="
              inline-flex items-center px-4 py-1 h-10 border border-gray-300 rounded-md
              shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50
              focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-purple-500"
          >
            <PrinterIcon className="-ml-1 mr-2 h-5 w-5 text-gray-400" aria-hidden="true" />
            <ConfirmNumberPopup setNumber={setPrintNumber} number={printNumber} setOpen={setPrintOpen} open={printOpen} submitForm={printAssemblageObjectLabel} label='Print labels'></ConfirmNumberPopup>
            Print Stickers
          </button>
        </DetailsActions>
      </DetailsBox>
      <ListContainer setSourceItems={setMaterials} rawSourceItems={shopOrderReceipt.Materials} title='Serie en batchnummers' pageSize={5} >
        <List>
          {materials.map((material) => (
            <Row
              key={material.ID}
              link={`/item/details/${material.Item}`}>
              <RowLine>
                <RowTitle>{material.ItemDescription}</RowTitle>
                {material.IsBatch &&
                  <RowElement>Batchnummer: &nbsp;<span className="text-sm font-medium text-blue truncate">{material.BatchNumber}</span></RowElement>
                }
                {material.IsSerial &&
                  <RowElement>Serienummer: &nbsp;<span className="text-sm font-medium text-blue truncate">{material.SerialNumber}</span></RowElement>
                }
              </RowLine>
              <RowLine>
                <RowElement><CubeIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                  Artikel: {material.ItemCode}
                </RowElement>
              </RowLine>
            </Row>
          ))}
        </List>
      </ListContainer>
    </div>
  );
};

export default ShopOrderReceiptDetails;
