import React from 'react';
import { ChevronRightIcon } from '@heroicons/react/solid';
import logoFull from '../../images/logo-text.jpg';
import { links } from '../../static-data/not-found';
import { Link } from 'react-router-dom';

const NotFound = () => {
  return (
    <div className="flex flex-col h-screen">
      <header>
        <div className="pt-16">
          <img
            className="mx-auto h-16 w-auto"
            src={logoFull}
            alt="Cadeautje.me"/>
          <div className="text-center">
            <p className="text-sm font-semibold text-blue-600 uppercase tracking-wide">404 error</p>
            <h1 className="mt-2 text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">
              Sorry, pagina niet gevonden
            </h1>
            <p className="mt-2 text-lg text-gray-500">De pagina die je zoekt lijkt helaas niet (meer) te bestaan</p>
          </div>
        </div>
      </header>
      <main className="max-w-7xl w-full mx-auto flex-grow px-4 sm:px-6 lg:px-8">
        <div className="max-w-xl mx-auto py-6 sm:py-8">
          <div className="mt-12">
            <h2 className="text-sm font-semibold text-gray-500 tracking-wide uppercase">Algemene pagina&apos;s</h2>
            <ul role="list" className="mt-4 border-t border-b border-gray-200 divide-y divide-gray-200">
              {links.map((link, linkIdx) => (
                <Link
                  key={linkIdx}
                  className="relative py-6 flex items-start space-x-4 hover:bg-milkywhite"
                  role="listitem"
                  to={link.link}>

                  <div className="flex-shrink-0">
                    <span className="flex items-center justify-center h-12 w-12 rounded-lg bg-blue-50">
                      <link.icon className="h-6 w-6 text-blue-700" aria-hidden="true" />
                    </span>
                  </div>
                  <div className="min-w-0 flex-1">
                    <h3 className="text-base font-medium text-gray-900">
                      <span className="rounded-sm focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-blue-500">
                        <span className="absolute inset-0" aria-hidden="true" />
                        {link.title}
                      </span>
                    </h3>
                    <p className="text-base text-gray-500">{link.description}</p>
                  </div>
                  <div className="flex-shrink-0 self-center">
                    <ChevronRightIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                  </div>
                </Link>
              ))}
            </ul>
            <div className="mt-8">
              <Link
                to="/"
                className="text-base font-medium text-gray-900 hover:text-gray-700">
                Of ga terug naar home<span aria-hidden="true"> &rarr;</span>
              </Link>
            </div>
          </div>
        </div>
      </main>
      <footer className="max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8">
        <div className="border-t border-gray-200 py-6 text-center md:flex md:justify-between">
          <p className="text-base text-gray-400">&copy; STS-Industires b.v. All rights reserved.</p>
        </div>
      </footer>
    </div>
  );
};

export default NotFound;
